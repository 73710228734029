import React from 'react';

const ButtonIcon = (props) => {
  return (
    <div style={{cursor: 'pointer', marginTop: '4px', marginLeft: 'auto', order: '3'}}>
      <span onClick={props.onClick}>{props.children}</span>
    </div>
  );
};

export default ButtonIcon;