import { ActionTypes } from '../actions/actionTypes';
import {Action} from "../actions/interfaces/types";

export interface NotificationState {
  show: boolean;
  type: string;
  message: string;
}

const initialState: NotificationState = {
  show: false,
  type: 'info',
  message: '',
};

const reducer = (state: NotificationState = initialState, action: Action): NotificationState => {
  switch (action.type) {
    case ActionTypes.SUCCESS_NOTIFICATION_SHOWED:
      return {
        show: true,
        type: 'success',
        message: action.message,
      };
    case ActionTypes.ERROR_NOTIFICATION_SHOWED:
      return {
        show: true,
        type: 'error',
        message: action.message,
      };
    case ActionTypes.NOTIFICATION_HIDDEN:
      return {
        show: false,
        type: 'info',
        message: 'Default',
      };
    default:
      return state;
  }
};

export default reducer;
