import { StoreState } from '../reducers';

export const currencyList = (state: StoreState) => state.picklist.currencies;

export const timezoneList = (state: StoreState) => state.picklist.timezones;

export const countryList = (state: StoreState) => state.picklist.countries;

export const holidayCalendarList = (state: StoreState) =>
  state.picklist.holidayCalendars;
