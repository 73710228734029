import { OfficeRequestData } from '../containers/Configuration/Offices/Interfaces';
import axios from '../utils/axios-backend';

export const getOffices = (token: string) => {
  return axios
    .get('/setup/offices', {
      headers: { Authorization: 'Bearer ' + token },
    })
    .then((response) => response.data.data);
};

export const saveOffice = (token: string, data: OfficeRequestData) => {
  return axios
    .put(`/setup/offices/${data.id}`, data, {
      headers: { Authorization: 'Bearer ' + token },
    })
    .then((response) => response.data.data);
};

export const createOffice = (token: string, data: OfficeRequestData) => {
  return axios
    .post(`/setup/offices`, data, {
      headers: { Authorization: 'Bearer ' + token },
    })
    .then((response) => response.data.data);
};

export const deleteOffice = (token: string, id: number) => {
  return axios
    .delete(`/setup/offices/${id}`, {
      headers: { Authorization: 'Bearer ' + token },
    })
    .then((response) => response.data.data);
};
