import { call, put, select, takeEvery } from 'redux-saga/effects';
import { ActionTypes } from '../actions/actionTypes';
import { StoreState } from '../reducers';
import * as actions from '../actions';
import {
  getPositions,
  updatePosition,
  deletePosition,
  createPosition,
} from '../../services/positions';
import {
  DeletePositionAction,
  SavePositionAction,
} from '../actions/interfaces/position';

const getToken = (state: StoreState) => state.auth.token;

function* loadPositions(): any {
  try {
    const token = yield select(getToken);
    const positions = yield call(getPositions, token);

    yield put(actions.positionPageEnterSuccess(positions));
  } catch (error) {
    console.log(error);
    yield put(actions.showErrorNotification(error.message));
  }
}

function* savePosition(action: SavePositionAction): any {
  try {
    const token = yield select(getToken);

    yield call(
      action.position.id === -1 ? createPosition : updatePosition,
      token,
      action.position
    );

    yield put(
      actions.showSuccessNotification(
        `${action.position.name} has been successfully updated.`
      )
    );
    yield put(actions.savePositionSuccess(action.position));
    yield loadPositions();
  } catch (error) {
    console.log(error);
    yield put(actions.showErrorNotification(error.message));
  }
}

function* removePosition(action: DeletePositionAction): any {
  try {
    const token = yield select(getToken);
    yield call(deletePosition, token, action.id);

    yield put(
      actions.showSuccessNotification(
        `Position has been successfully removed.`
      )
    );
    yield put(actions.deletePositionSuccess(action.id));
    yield loadPositions();
  } catch (error) {
    console.log(error);
    yield put(actions.showErrorNotification(`An error occurred while try to delete the position`));
    yield put(actions.deletePositionFailed());
  }
}

export default function* positionSaga() {
  yield takeEvery(ActionTypes.POSITION_PAGE_ENTER, loadPositions);
  yield takeEvery(ActionTypes.SAVE_POSITION, savePosition);
  yield takeEvery(ActionTypes.DELETE_POSITION, removePosition);
}
