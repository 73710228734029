import axios from '../utils/axios-backend';
import {OccupationType, OccupationTypeResponse} from "../models/OccupationType";

export const getOccupationTypes = (token: string) => {
    return axios
        .get('/setup/occupation_types', {
            headers: { Authorization: 'Bearer ' + token },
        })
        .then((response) => mapResponseToModel(response.data.data));
};

export const updateOccupationType = (token: string, occupationType: OccupationType) => {
    return axios
        .put(`/setup/occupation_types/${occupationType.id}`, mapModelToRequest(occupationType), {
            headers: { Authorization: 'Bearer ' + token },
        })
        .then((response) => response.data.data);
};

export const createOccupationType = (token: string, occupationType: OccupationType) => {
    return axios
        .post(`/setup/occupation_types`, mapModelToRequest(occupationType), {
            headers: { Authorization: 'Bearer ' + token },
        })
        .then((response) => response.data.data);
};

export const deleteOccupationType = (token: string, id: number) => {
    return axios
        .delete(`/setup/occupation_types/${id}`, {
            headers: { Authorization: 'Bearer ' + token },
        })
        .then((response) => response.data.data);
};

const mapResponseToModel = (
    occupationTypeList: OccupationTypeResponse[]
): OccupationType[] => {
    return occupationTypeList.map(
        (resp: OccupationTypeResponse): OccupationType => {
            return {
                key: resp.id,
                id: resp.id,
                name: resp.name,
            };
        }
    );
};

const mapModelToRequest = (occupationType: OccupationType) => {
    return {
        id: -1 === occupationType.id ? null : occupationType.id,
        name: occupationType.name,
    };
};
