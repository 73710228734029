import React, {Component} from 'react';

class Absence extends Component {
  render() {
    return (
      <div>
        <p>Absence Configuration Component</p>
      </div>
    );
  }
}

export default Absence;