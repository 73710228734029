import { OccupationType } from '../../models/OccupationType';
import { ActionTypes } from '../actions/actionTypes';
import { Action } from '../actions/interfaces/types';

export interface OccupationTypeState {
    loading: boolean;
    list: OccupationType[];
    selectedOccupationType: OccupationType | null;
}

const initialStates: OccupationTypeState = {
    loading: false,
    list: [],
    selectedOccupationType: null,
};

const updateOccupationTypeByName = (
    state: OccupationTypeState,
    occupationType: OccupationType
) => {
    const newOccupationTypeList = state.list.map((element: OccupationType) => {
        if (element.name === occupationType.name) {
            return occupationType;
        }

        return element;
    });

    return {
        ...state,
        loading: false,
        list: newOccupationTypeList,
    };
};

const reducer = (
    state: OccupationTypeState = initialStates,
    action: Action
): OccupationTypeState => {
    switch (action.type) {
        case ActionTypes.OCCUPATION_TYPE_PAGE_ENTER:
            return {
                ...state,
                loading: true,
            };
        case ActionTypes.OCCUPATION_TYPE_PAGE_ENTER_SUCCESS:
            return {
                ...state,
                loading: false,
                list: action.occupationTypes,
            };
        case ActionTypes.SET_SELECTED_OCCUPATION_TYPE:
            const occupationTypes = [...state.list];
            const selectedOccupationType = occupationTypes.find((d) => d.id === action.id);
            return {
                ...state,
                selectedOccupationType: selectedOccupationType ?? null,
            };
        case ActionTypes.SAVE_OCCUPATION_TYPE:
            return {
                ...state,
                loading: true,
            };
        case ActionTypes.SAVE_OCCUPATION_TYPE_SUCCESS:
            return updateOccupationTypeByName(state, action.occupationType);
        case ActionTypes.DELETE_OCCUPATION_TYPE:
            return {
                ...state,
                loading: true,
            };
        case ActionTypes.DELETE_OCCUPATION_TYPE_SUCCESS:
            return {
                ...state,
                list: state.list.filter(
                    (occupationType: OccupationType) => occupationType.id !== action.id
                ),
                loading: false,
            };
        case ActionTypes.DELETE_OCCUPATION_TYPE_FAILED:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export default reducer;
