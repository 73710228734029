import axios from '../utils/axios-backend';
import {Team, TeamResponse} from "../models/Team";

export const getTeams = (token: string) => {
  return axios
    .get('/setup/teams', {
      headers: { Authorization: 'Bearer ' + token },
    })
    .then((response) => mapResponseToModel(response.data.data));
};

export const updateTeam = (token: string, team: Team) => {
  return axios
    .put(`/setup/teams/${team.id}`, mapModelToRequest(team), {
      headers: { Authorization: 'Bearer ' + token },
    })
    .then((response) => response.data.data);
};

export const createTeam = (token: string, team: Team) => {
  return axios
    .post(`/setup/teams`, mapModelToRequest(team), {
      headers: { Authorization: 'Bearer ' + token },
    })
    .then((response) => response.data.data);
};

export const deleteTeam = (token: string, id: number) => {
  return axios
    .delete(`/setup/teams/${id}`, {
      headers: { Authorization: 'Bearer ' + token },
    })
    .then((response) => response.data.data);
};

const mapResponseToModel = (
  teamList: TeamResponse[]
): Team[] => {
  return teamList.map(
    (resp: TeamResponse): Team => {
      return {
        key: resp.id,
        id: resp.id,
        name: resp.name,
      };
    }
  );
};

const mapModelToRequest = (team: Team) => {
  return {
    id: -1 === team.id ? null : team.id,
    name: team.name,
  };
};
