import { Team } from '../../models/Team';
import { ActionTypes } from './actionTypes';
import {
    TeamPageEnterAction,
    TeamPageEnterSuccessAction,
    SetSelectedTeamAction,
    SaveTeamAction,
    SaveTeamSuccessAction,
    DeleteTeamAction,
    DeleteTeamSuccessAction,
    DeleteTeamFailedAction,
} from './interfaces/team';

export const teamPageEnter = (): TeamPageEnterAction => {
    return {
        type: ActionTypes.TEAM_PAGE_ENTER,
    };
};

export const teamPageEnterSuccess = (
    teams: Team[]
): TeamPageEnterSuccessAction => {
    return {
        type: ActionTypes.TEAM_PAGE_ENTER_SUCCESS,
        teams: teams,
    };
};

export const setSelectedTeam = (
    id: number | null
): SetSelectedTeamAction => {
    return {
        type: ActionTypes.SET_SELECTED_TEAM,
        id: id,
    };
};

export const saveTeam = (
    team: Team
): SaveTeamAction => {
    return {
        type: ActionTypes.SAVE_TEAM,
        team: team,
    };
};

export const saveTeamSuccess = (
    team: Team
): SaveTeamSuccessAction => {
    return {
        type: ActionTypes.SAVE_TEAM_SUCCESS,
        team: team,
    };
};

export const deleteTeam = (id: number): DeleteTeamAction => {
    return {
        type: ActionTypes.DELETE_TEAM,
        id: id,
    };
};

export const deleteTeamSuccess = (
    id: number
): DeleteTeamSuccessAction => {
    return {
        type: ActionTypes.DELETE_TEAM_SUCCESS,
        id: id,
    };
};

export const deleteTeamFailed = (): DeleteTeamFailedAction => {
    return {
        type: ActionTypes.DELETE_TEAM_FAILED,
    };
};
