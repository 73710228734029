import { OccupationType } from '../../models/OccupationType';
import { ActionTypes } from './actionTypes';
import {
    OccupationTypePageEnterAction,
    OccupationTypePageEnterSuccessAction,
    SetSelectedOccupationTypeAction,
    SaveOccupationTypeAction,
    SaveOccupationTypeSuccessAction,
    DeleteOccupationTypeAction,
    DeleteOccupationTypeSuccessAction,
    DeleteOccupationTypeFailedAction,
} from './interfaces/occupationType';

export const occupationTypePageEnter = (): OccupationTypePageEnterAction => {
    return {
        type: ActionTypes.OCCUPATION_TYPE_PAGE_ENTER,
    };
};

export const occupationTypePageEnterSuccess = (
    occupationTypes: OccupationType[]
): OccupationTypePageEnterSuccessAction => {
    return {
        type: ActionTypes.OCCUPATION_TYPE_PAGE_ENTER_SUCCESS,
        occupationTypes: occupationTypes,
    };
};

export const setSelectedOccupationType = (
    id: number | null
): SetSelectedOccupationTypeAction => {
    return {
        type: ActionTypes.SET_SELECTED_OCCUPATION_TYPE,
        id: id,
    };
};

export const saveOccupationType = (
    occupationType: OccupationType
): SaveOccupationTypeAction => {
    return {
        type: ActionTypes.SAVE_OCCUPATION_TYPE,
        occupationType: occupationType,
    };
};

export const saveOccupationTypeSuccess = (
    occupationType: OccupationType
): SaveOccupationTypeSuccessAction => {
    return {
        type: ActionTypes.SAVE_OCCUPATION_TYPE_SUCCESS,
        occupationType: occupationType,
    };
};

export const deleteOccupationType = (id: number): DeleteOccupationTypeAction => {
    return {
        type: ActionTypes.DELETE_OCCUPATION_TYPE,
        id: id,
    };
};

export const deleteOccupationTypeSuccess = (
    id: number
): DeleteOccupationTypeSuccessAction => {
    return {
        type: ActionTypes.DELETE_OCCUPATION_TYPE_SUCCESS,
        id: id,
    };
};

export const deleteOccupationTypeFailed = (): DeleteOccupationTypeFailedAction => {
    return {
        type: ActionTypes.DELETE_OCCUPATION_TYPE_FAILED,
    };
};
