import axios from '../utils/axios-backend';
import {DocumentType, DocumentTypeResponse} from "../models/DocumentType";

export const getDocumentTypes = (token: string) => {
  return axios
    .get('/setup/document_types', {
      headers: { Authorization: 'Bearer ' + token },
    })
    .then((response) => mapResponseToModel(response.data.data));
};

export const updateDocumentType = (token: string, documentType: DocumentType) => {
  return axios
    .put(`/setup/document_types/${documentType.id}`, mapModelToRequest(documentType), {
      headers: { Authorization: 'Bearer ' + token },
    })
    .then((response) => response.data.data);
};

export const createDocumentType = (token: string, documentType: DocumentType) => {
  return axios
    .post(`/setup/document_types`, mapModelToRequest(documentType), {
      headers: { Authorization: 'Bearer ' + token },
    })
    .then((response) => response.data.data);
};

export const deleteDocumentType = (token: string, id: number) => {
  return axios
    .delete(`/setup/document_types/${id}`, {
      headers: { Authorization: 'Bearer ' + token },
    })
    .then((response) => response.data.data);
};

const mapResponseToModel = (
  documentTypeList: DocumentTypeResponse[]
): DocumentType[] => {
  return documentTypeList.map(
    (resp: DocumentTypeResponse): DocumentType => {
      return {
        key: resp.id,
        id: resp.id,
        name: resp.name,
      };
    }
  );
};

const mapModelToRequest = (documentType: DocumentType) => {
  return {
    id: -1 === documentType.id ? null : documentType.id,
    name: documentType.name,
  };
};
