import { Alert, Button, Col, Form, Input, Modal, Row, Table } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classes from './DocumentTypes.module.css';
import * as actions from '../../../store/actions';
import {
  documentTypeList as getDocumentTypeList,
  getSelectedDocumentType,
  isLoading,
} from '../../../store/selectors/documentTypeSelector';
import FormActionButton from '../../../components/UI/FormActionButtons/FormActionButton';
import { ExclamationCircleOutlined } from '@ant-design/icons';
const { confirm } = Modal;

interface DocumentTypeRow {
  id: number;
  name: string;
}

let defaultDocumentType: DocumentTypeRow = {
  id: -1,
  name: '',
};

const DocumentTypes = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.documentTypePageEnter());
  }, [dispatch]);

  const documentTypeList = useSelector(getDocumentTypeList);
  const loading = useSelector(isLoading);
  const selectedDocumentType = useSelector(getSelectedDocumentType);

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: 'actions',
      render: (record: DocumentTypeRow) => (
        <>
          <Button type="link" onClick={() => editDocumentType(record)}>
            Edit
          </Button>{' '}
          |{' '}
          <Button type="link" onClick={() => deleteDocumentType(record)}>
            Delete
          </Button>
        </>
      ),
    },
  ];

  const resetForm = () => {
    form.setFieldsValue(defaultDocumentType);
    dispatch(actions.setSelectedDocumentType(null));
  };

  const onSaveDocumentType = () => {
    let documentType: DocumentTypeRow = { ...defaultDocumentType };

    if (null !== selectedDocumentType) {
      documentType = { ...selectedDocumentType };
    }

    const values = form.getFieldsValue();
    documentType = {
      ...documentType,
      name: values.name,
    };

    dispatch(actions.saveDocumentType(documentType));
  };

  const isNameUniqueValidator = (name: string): any => {
    const isValid =
      documentTypeList.filter(
        (documentType) => documentType.id !== selectedDocumentType?.id && documentType.name === name
      ).length === 0;

    if (name && !isValid) {
      return Promise.reject(`The name ${name} already exist.`);
    }

    return Promise.resolve('');
  };

  const deleteDocumentType = (record: DocumentTypeRow): void => {
    confirm({
      title: `Are you sure delete this documentType - ${record.name}? `,
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        dispatch(actions.deleteDocumentType(record.id));
      },
      onCancel() {
        console.log(`Cancelled deletion of Document Type - ${record.name}`);
      },
    });
  };

  const editDocumentType = (record: DocumentTypeRow): void => {
    form.setFieldsValue(record);
    dispatch(actions.setSelectedDocumentType(record.id));
  };

  return (
    <div>
      <div className={classes.Offices}>
        <div className={classes.TitleContainer}>
          <h2>Manage DocumentTypes</h2>
        </div>

        <Alert
          style={{ marginTop: '20px', marginBottom: '10px' }}
          message="This account was pre-filled with demo employees. To clear all demo data, please complete the setup wizard."
          type="warning"
        />

        <Row>
          <div className={classes.SubTitle}>
            <h3>DocumentTypes</h3>
          </div>
        </Row>
        <>
          <div style={{ marginTop: '20px', marginBottom: '20px' }}></div>
        </>

        <Row>
          <>
            <Col span={6}>
              <div className={classes.FormContent}>
                <Form
                  layout="vertical"
                  form={form}
                  initialValues={selectedDocumentType ?? {}}
                >
                  <Form.Item
                    name="name"
                    label="DocumentType Name"
                    rules={[
                      {
                        required: true,
                        message: 'Please ensure you entered a name!',
                      },
                      {
                        validator: (_, value) => isNameUniqueValidator(value),
                      },
                    ]}
                  >
                    <Input placeholder="eg. Justice League DocumentType" />
                  </Form.Item>
                  <Form.Item>
                    <FormActionButton
                      disabled={loading}
                      onSubmit={onSaveDocumentType}
                      onCancel={resetForm}
                    />
                  </Form.Item>
                </Form>
              </div>
            </Col>

            <Col span={16} offset={1}>
              <div>
                <Table
                  dataSource={documentTypeList}
                  columns={columns}
                  loading={loading}
                />
                ;
              </div>
            </Col>
          </>
        </Row>
      </div>
    </div>
  );
};

export default DocumentTypes;
