import { OccupationType } from '../../models/OccupationType';
import { StoreState } from '../reducers';

export const occupationTypeList = (state: StoreState): OccupationType[] =>
  state.occupationTypes.list.map<OccupationType>((occupationType) => {
    return { key: occupationType.id, ...occupationType };
  });

export const isLoading = (state: StoreState): boolean =>
  state.occupationTypes.loading;

export const getSelectedOccupationType = (state: StoreState): OccupationType | null =>
  state.occupationTypes.selectedOccupationType;
