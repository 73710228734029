import { Department } from '../../models/Department';
import { StoreState } from '../reducers';

export const departmentList = (state: StoreState): Department[] =>
  state.departments.list.map<Department>((dept) => {
    return { key: dept.id, ...dept };
  });

export const isLoading = (state: StoreState): boolean =>
  state.departments.loading;

export const getSelectedDepartment = (state: StoreState): Department | null =>
  state.departments.selectedDepartment;
