import { ActionTypes } from './actionTypes';
import {Country, Currency, HolidayCalendar, Language, TimeZone} from "../../models/Picklist";
import {
  FetchCountriesSuccessAction,
  FetchCurrenciesSuccessAction,
  FetchHolidayCalendarSuccessAction,
  FetchLanguagesSuccessAction,
  FetchTimezonesSuccessAction
} from "./interfaces/picklist";

export const fetchCurrenciesSuccess = (
  response: Currency[]
): FetchCurrenciesSuccessAction => {
  return {
    type: ActionTypes.FETCH_CURRENCIES_SUCCESS,
    currencies: response,
  };
};

export const fetchLanguagesSuccess = (
  response: Language[]
): FetchLanguagesSuccessAction => {
  return {
    type: ActionTypes.FETCH_LANGUAGES_SUCCESS,
    languages: response,
  };
};

export const fetchTimezonesSuccess = (
  response: TimeZone[]
): FetchTimezonesSuccessAction => {
  return {
    type: ActionTypes.FETCH_TIMEZONES_SUCCESS,
    timezones: response,
  };
};

export const fetchCountriesSuccess = (
  response: Country[]
): FetchCountriesSuccessAction => {
  return {
    type: ActionTypes.FETCH_COUNTRIES_SUCCESS,
    countries: response,
  };
};

export const fetchHolidayCalendarSuccess = (
  response: HolidayCalendar[]
): FetchHolidayCalendarSuccessAction => {
  return {
    type: ActionTypes.FETCH_HOLIDAY_CALENDAR_SUCCESS,
    holidayCalendars: response,
  };
};
