import { ActionTypes } from './actionTypes';
import {
  CompanyProfileUpdateFailedAction,
  CompanyProfileUpdateStartAction,
  CompanyProfileUpdateSuccessAction,
  GetProfileAction,
  GetProfileFailedAction,
  GetProfileStartAction,
  GetProfileSuccessAction,
  InitCompanyProfileAction,
  InitCompanyProfileFailedAction,
  InitCompanyProfileSuccessAction,
  UpdateCompanyProfileAction,
} from './interfaces/company';
import { Company } from '../../models/Company';

export const getProfileStart = (): GetProfileStartAction => {
  return {
    type: ActionTypes.FETCH_COMPANY_PROFILE_START,
  };
};

export const getProfile = (): GetProfileAction => {
  return {
    type: ActionTypes.FETCH_COMPANY_PROFILE,
  };
};

export const getProfileSuccess = (data: Company): GetProfileSuccessAction => {
  return {
    type: ActionTypes.FETCH_COMPANY_PROFILE_SUCCESS,
    company: data,
  };
};

//@Todo Find out the type for this
export const getProfileFailed = (error: any): GetProfileFailedAction => {
  return {
    type: ActionTypes.FETCH_COMPANY_PROFILE_FAILED,
    error: error,
  };
};

export const initCompanyProfile = (): InitCompanyProfileAction => {
  return {
    type: ActionTypes.COMPANY_PROFILE_ENTER,
  };
};

//@Todo Find out the type for this
export const initCompanyProfileSuccess = (
  picklist: any
): InitCompanyProfileSuccessAction => {
  return {
    type: ActionTypes.COMPANY_PROFILE_ENTER_SUCCESS,
    picklist: picklist,
  };
};

//@Todo Find out the type for this
export const initCompanyProfileFailed = (
  error: any
): InitCompanyProfileFailedAction => {
  return {
    type: ActionTypes.COMPANY_PROFILE_ENTER_FAILED,
    error: error,
  };
};

//@Todo Find out the type for this
export const updateCompanyProfile = (data: any): UpdateCompanyProfileAction => {
  return {
    type: ActionTypes.COMPANY_PROFILE_UPDATE,
    payload: data,
  };
};

export const companyProfileUpdateStart = (): CompanyProfileUpdateStartAction => {
  return {
    type: ActionTypes.COMPANY_PROFILE_UPDATE_START,
  };
};

//@Todo Find out the type for this
export const companyProfileUpdateSuccess = (
  updatedProfile: any
): CompanyProfileUpdateSuccessAction => {
  return {
    type: ActionTypes.COMPANY_PROFILE_UPDATE_SUCCESS,
    updatedProfile: updatedProfile,
  };
};

//@Todo Find out the type for this
export const companyProfileUpdateFailed = (
  error: any
): CompanyProfileUpdateFailedAction => {
  return {
    type: ActionTypes.COMPANY_PROFILE_UPDATE_FAILED,
    error: error,
  };
};
