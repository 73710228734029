import React, {Component} from 'react';
import {Alert, Button, Checkbox, Form, Input, Spin, Upload, message} from "antd";
import classes from './Company.module.css';
import {ArrowRightOutlined, CloseOutlined, UploadOutlined} from "@ant-design/icons";
import * as actions from '../../../store/actions';
import {connect} from "react-redux";
import {UPLOAD_URL} from '../../../AppConstants';
import MSelect from "../../../components/UI/MSelect";

class Company extends Component {

  state = {
    isEditing: false,
    uploadedLogo: null
  };

  componentDidMount() {
    this.props.onFetchProfile();
    this.props.initCompanyProfileEdit();
  }

  toggleEdit = (event) => {
    this.setState(prevState => {
      return {isEditing: !prevState.isEditing};
    });
  };

  getYesNo = (value) => {
    return Boolean(parseInt(value)) ? 'Yes' : 'No';
  };

  submitHandler = values => {
    const formData = {...values};
    formData.logo = this.state.uploadedLogo ? this.state.uploadedLogo.path : null;
    this.props.onUpdateCompanyProfile(formData);
  };

  cancelHandler = () => {
    this.setState({isEditing: false});
  };

  fileUploadHandler = (info) => {
    if (info.file.status === 'done') {
      const {name, path, url} = info.file.response.data;
      this.setState({uploadedLogo: {name, path, url}})
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  render() {
    const spinning = this.props.profile === null;
    const profile = {...this.props.profile};
    const initialValues = spinning ? {} : {
      company_name: profile.name,
      registration_code: profile.registration_code,
      is_subcompany_enabled: Boolean(parseInt(profile.is_subcompany_enabled)),
      is_email_notification_enabled: Boolean(parseInt(profile.is_email_notification_enabled)),
      currency: profile.currency.id,
      timezone: profile.timezone.id,
      language: profile.language.id,
      industry_category: profile.industry_category.id,
      phone_contact: profile.phone_contact,
      website: profile.website,
      contact_email: profile.contact_email,
      logo_url: profile.logo_url,
    };

    let currenciesPickList = [];
    if (this.props.currencies) {
      currenciesPickList = this.props.currencies.map(item => {
        return {
          ...item,
          name: item.name + ' (' + item.symbol + ')'
        }
      });
    }

    const layout = {
      labelCol: { span: 12},
      wrapperCol: { span: 12},
      layout: 'horizontal',
    };
    const firstRowClass = this.state.isEditing
      ? classes.FormRow : [classes.FormRow, classes.FirstRow, classes.WithBottomBorder].join(" ");
    const rowClass = this.state.isEditing
      ? classes.FormRow : [classes.FormRow, classes.WithBottomBorder].join(" ");

    return (
      <div className={classes.Company}>
        <div className={classes.TitleContainer}>
          <h2>Company </h2><span className={classes.EditAction} onClick={this.toggleEdit}>
          {this.state.isEditing ? '[Done]' : '[Edit]'}
        </span>
        </div>
        <Alert style={{marginTop: '20px'}}
          message="This account was pre-filled with demo employees. To clear all demo data, please complete the setup wizard."
          type="warning"
        />
        <div className={classes.Content}>
          <span className={classes.SubTitle}>Company Information</span>

          {spinning ? (<div style={{textAlign: 'center'}}><Spin tip="Loading..." /></div>) :
            <Form
              {...layout}
              labelAlign="left"
              colon={false}
              onFinish={this.submitHandler}
              initialValues={initialValues}
            >
              <Form.Item
                name="company_name"
                label="Company Name"
                className={firstRowClass}
              >
                {!this.state.isEditing ? profile.name : (
                  <Input placeholder="Company Name" />
                )}
              </Form.Item>
              <Form.Item
                name="registration_code"
                label="Company Registration Code"
                className={firstRowClass}
              >
                {!this.state.isEditing ? profile.registration_code : (
                  <Input placeholder="Registration Number" />
                )}
              </Form.Item>
              <Form.Item
                name="is_subcompany_enabled"
                label="Sub-companies enabled"
                valuePropName={Boolean(parseInt(profile.is_subcompany_enabled)) ? "checked" : null}
                className={rowClass}
              >
                {!this.state.isEditing ? this.getYesNo(profile.is_subcompany_enabled) : (
                  <Checkbox>{this.getYesNo(profile.is_subcompany_enabled)}</Checkbox>
                )}
              </Form.Item>
              <Form.Item
                name="is_email_notification_enabled"
                label="Email notification enabled"
                valuePropName={Boolean(parseInt(profile.is_email_notification_enabled)) ? "checked" : null}
                className={rowClass}
              >
                {!this.state.isEditing ? this.getYesNo(profile.is_email_notification_enabled) : (
                  <Checkbox>{this.getYesNo(profile.is_email_notification_enabled)}</Checkbox>
                )}
              </Form.Item>
              <Form.Item
                name="language"
                label="Language preference"
                className={rowClass}
              >
                {!this.state.isEditing ? profile.language.name
                  : <MSelect picklist={this.props.languages} style={{ width: '80%' }} />}
              </Form.Item>
              <Form.Item
                name="currency"
                label="Default Currency"
                className={rowClass}
              >
                {!this.state.isEditing ? profile.currency.name + ' (' + profile.currency.symbol + ')'
                  : <MSelect picklist={currenciesPickList} style={{ width: '80%' }} />
                }
              </Form.Item>
              <Form.Item
                name="industry_category"
                label="Industry"
                className={rowClass}
              >
                {!this.state.isEditing ? profile.industry_category.name
                  : <MSelect picklist={this.props.industryCategories} style={{ width: '80%' }} />
                }
              </Form.Item>
              <Form.Item
                name="timezone"
                label="Timezone"
                className={rowClass}
              >
                {!this.state.isEditing ? profile.timezone.name : <MSelect picklist={this.props.timezones} style={{ width: '80%' }} />}
              </Form.Item>
              <Form.Item
                name="phone_contact"
                label="Phone Contact"
                className={rowClass}
              >
                {!this.state.isEditing ? profile.phone_contact : (
                  <Input
                    placeholder="Phone Contact"
                    style={{ width: '80%' }}
                  />
                )}
              </Form.Item>
              <Form.Item
                name="website"
                label="Website"
                className={rowClass}
              >
                {!this.state.isEditing ? profile.website : (
                  <Input
                    placeholder="Website"
                    style={{ width: '80%' }}
                  />
                )}
              </Form.Item>
              <Form.Item
                name="contact_email"
                label="Maintenance Email Contact"
                className={rowClass}
              >
                {!this.state.isEditing ? profile.contact_email : (
                  <Input
                    placeholder="Maintenance Email Contact"
                    style={{ width: '80%' }}
                  />
                )}
              </Form.Item>
              <Form.Item
                name="logo"
                label="Logo"
                className={rowClass}
              >
                {!this.state.isEditing ? <img src={profile.logo_url} alt="Logo" /> : (
                  <Upload
                    name="file"
                    action={UPLOAD_URL}
                    headers={{Authorization: 'Bearer ' + this.props.token}}
                    withCredentials={false}
                    onChange={this.fileUploadHandler}
                  >
                    <Button>
                      <UploadOutlined /> Click to Upload
                    </Button>
                  </Upload>
                )}
              </Form.Item>

              <div className={classes.ActionButtons}>
                {!this.state.isEditing ? null : (
                  <Form.Item wrapperCol={{span: 24 }}>
                    <Button
                      icon={<CloseOutlined />}
                      onClick={this.cancelHandler}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary" icon={<ArrowRightOutlined />}
                      htmlType="submit"
                      loading={this.props.loading}
                    >
                      Save Changes
                    </Button>
                  </Form.Item>
                )}
              </div>
            </Form>
          }
          </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.company.loading,
    token: state.auth.token,
    profile: state.company.profile,
    currencies: state.company.picklist.currencies,
    languages: state.company.picklist.languages,
    timezones: state.company.picklist.timezones,
    industryCategories: state.company.picklist.industryCategories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchProfile: () => dispatch(actions.getProfile()),
    initCompanyProfileEdit: () => dispatch(actions.initCompanyProfile()),
    onUpdateCompanyProfile: (data) => dispatch(actions.updateCompanyProfile(data))
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(Company);