import { Office } from '../../models/Office';
import { StoreState } from '../reducers';
import lodash from 'lodash';
import {
  Country,
  Currency,
  HolidayCalendar,
  TimeZone,
} from '../../models/Picklist';

export const officeList = (state: StoreState): Office[] => state.offices.list;

export const officeProcessState = (state: StoreState): boolean =>
  state.offices.loading;

export const getSelectedOffice = (state: StoreState): Office | null =>
  state.offices.selectedOffice;

export const officeDefault = (state: StoreState): Office => {
  // We should get some company defaults like timezone and currency.
  return {
    id: 0,
    name: '',
    timezone: lodash.first(state.picklist.timezones) as TimeZone,
    currency: lodash.first(state.picklist.currencies) as Currency,
    holiday_calendar: lodash.first(
      state.picklist.holidayCalendars
    ) as HolidayCalendar,
    address: {
      street: '',
      house_number: '',
      address_2: '',
      postcode: '',
      city: '',
      state: '',
      country: lodash.first(state.picklist.countries) as Country,
    },
    is_head_quarter: false,
    is_default: false,
    created_at: new Date(),
    updated_at: new Date(),
  };
};
