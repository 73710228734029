import { Alert, Button, Col, Form, Input, Modal, Row, Table } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classes from './Positions.module.css';
import * as actions from '../../../store/actions';
import {
  positionList as getPositionList,
  getSelectedPosition,
  isLoading,
} from '../../../store/selectors/positionSelector';
import FormActionButton from '../../../components/UI/FormActionButtons/FormActionButton';
import { ExclamationCircleOutlined } from '@ant-design/icons';
const { confirm } = Modal;

interface PositionRow {
  id: number;
  name: string;
}

let defaultPosition: PositionRow = {
  id: -1,
  name: '',
};

const Positions = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.positionPageEnter());
  }, [dispatch]);

  const positionList = useSelector(getPositionList);
  const loading = useSelector(isLoading);
  const selectedPosition = useSelector(getSelectedPosition);

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: 'actions',
      render: (record: PositionRow) => (
        <>
          <Button type="link" onClick={() => editPosition(record)}>
            Edit
          </Button>{' '}
          |{' '}
          <Button type="link" onClick={() => deletePosition(record)}>
            Delete
          </Button>
        </>
      ),
    },
  ];

  const resetForm = () => {
    form.setFieldsValue(defaultPosition);
    dispatch(actions.setSelectedPosition(null));
  };

  const onSavePosition = () => {
    let position: PositionRow = { ...defaultPosition };

    if (null !== selectedPosition) {
      position = { ...selectedPosition };
    }

    const values = form.getFieldsValue();
    position = {
      ...position,
      name: values.name,
    };

    dispatch(actions.savePosition(position));
  };

  const isNameUniqueValidator = (name: string): any => {
    const isValid =
      positionList.filter(
        (position) => position.id !== selectedPosition?.id && position.name === name
      ).length === 0;

    if (name && !isValid) {
      return Promise.reject(`The name ${name} already exist.`);
    }

    return Promise.resolve('');
  };

  const deletePosition = (record: PositionRow): void => {
    confirm({
      title: `Are you sure delete this position - ${record.name}? `,
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        dispatch(actions.deletePosition(record.id));
      },
      onCancel() {
        console.log(`Cancelled deletion of position - ${record.name}`);
      },
    });
  };

  const editPosition = (record: PositionRow): void => {
    form.setFieldsValue(record);
    dispatch(actions.setSelectedPosition(record.id));
  };

  return (
    <div>
      <div className={classes.Offices}>
        <div className={classes.TitleContainer}>
          <h2>Manage Positions</h2>
        </div>

        <Alert
          style={{ marginTop: '20px', marginBottom: '10px' }}
          message="This account was pre-filled with demo employees. To clear all demo data, please complete the setup wizard."
          type="warning"
        />

        <Row>
          <div className={classes.SubTitle}>
            <h3>Positions</h3>
          </div>
        </Row>
        <>
          <div style={{ marginTop: '20px', marginBottom: '20px' }}></div>
        </>

        <Row>
          <>
            <Col span={6}>
              <div className={classes.FormContent}>
                <Form
                  layout="vertical"
                  form={form}
                  initialValues={selectedPosition ?? {}}
                >
                  <Form.Item
                    name="name"
                    label="Position Name"
                    rules={[
                      {
                        required: true,
                        message: 'Please ensure you entered a name!',
                      },
                      {
                        validator: (_, value) => isNameUniqueValidator(value),
                      },
                    ]}
                  >
                    <Input placeholder="eg. Sales Representative" />
                  </Form.Item>
                  <Form.Item>
                    <FormActionButton
                      disabled={loading}
                      onSubmit={onSavePosition}
                      onCancel={resetForm}
                    />
                  </Form.Item>
                </Form>
              </div>
            </Col>

            <Col span={16} offset={1}>
              <div>
                <Table
                  dataSource={positionList}
                  columns={columns}
                  loading={loading}
                />
                ;
              </div>
            </Col>
          </>
        </Row>
      </div>
    </div>
  );
};

export default Positions;
