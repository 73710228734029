import { Department } from '../../models/Department';
import { ActionTypes } from './actionTypes';
import {
  DepartmentPageEnterAction,
  DepartmentPageEnterSuccessAction,
  SetSelectedDepartmentAction,
  SaveDepartmentAction,
  SaveDepartmentSuccessAction,
  DeleteDepartmentAction,
  DeleteDepartmentSuccessAction, DeleteDepartmentFailedAction,
} from './interfaces/department';

export const departmentPageEnter = (): DepartmentPageEnterAction => {
  return {
    type: ActionTypes.DEPARTMENT_PAGE_ENTER,
  };
};

export const departmentPageEnterSuccess = (
  departments: Department[]
): DepartmentPageEnterSuccessAction => {
  return {
    type: ActionTypes.DEPARTMENT_PAGE_ENTER_SUCCESS,
    departments: departments,
  };
};

export const setSelectedDepartment = (
  id: number | null
): SetSelectedDepartmentAction => {
  return {
    type: ActionTypes.SET_SELECTED_DEPARTMENT,
    id: id,
  };
};

export const saveDepartment = (
  department: Department
): SaveDepartmentAction => {
  return {
    type: ActionTypes.SAVE_DEPARTMENT,
    department: department,
  };
};

export const saveDepartmentSuccess = (
  department: Department
): SaveDepartmentSuccessAction => {
  return {
    type: ActionTypes.SAVE_DEPARTMENT_SUCCESS,
    department: department,
  };
};

export const deleteDepartment = (id: number): DeleteDepartmentAction => {
  return {
    type: ActionTypes.DELETE_DEPARTMENT,
    id: id,
  };
};

export const deleteDepartmentSuccess = (
  id: number
): DeleteDepartmentSuccessAction => {
  return {
    type: ActionTypes.DELETE_DEPARTMENT_SUCCESS,
    id: id,
  };
};

export const deleteDepartmentFailed = (): DeleteDepartmentFailedAction => {
  return {
    type: ActionTypes.DELETE_DEPARTMENT_FAILED,
  };
};
