import { all, call, put, select, takeEvery } from '@redux-saga/core/effects';
import { ActionTypes } from '../actions/actionTypes';
import * as actions from '../actions';
import {
  getOffices,
  saveOffice,
  createOffice,
  deleteOffice,
} from '../../services/offices';
import {
  _loadCurrencies,
  _loadCountries,
  _loadTimezones,
  _loadHolidayCalendars,
} from './picklistSaga';
import {
  CreateOfficeAction,
  DeleteOfficeAction,
  SaveOfficeAction,
} from '../actions/interfaces/office';
import { StoreState } from '../reducers';

const getToken = (state: StoreState) => state.auth.token;

function* loadOfficesInitData(): any {
  try {
    const token = yield select(getToken);
    const [offices] = yield all([
      call(getOffices, token),
      _loadCurrencies(),
      _loadTimezones(),
      _loadCountries(),
      _loadHolidayCalendars(),
    ]);

    yield put(actions.officePageEnterSuccess(offices));
    if (0 !== offices.length) {
      yield put(actions.setSelectedOffice(offices[0]));
    }
  } catch (error) {
    console.log(error);
    yield put(actions.initCompanyProfileFailed(error));
  }
}

function* saveOfficeData(action: SaveOfficeAction) {
  try {
    const token = yield select(getToken);
    const respData = yield call(saveOffice, token, action.data);
    yield put(actions.showSuccessNotification('Office saved successfully!'));
    yield put(actions.saveOfficeSuccess(respData));
  } catch (error) {
    console.log(error);
    yield put(
      actions.showErrorNotification('Failed to save office. Please try again.')
    );
    yield put(actions.saveOfficeFailed(error));
  }
}

function* createOfficeData(action: CreateOfficeAction) {
  try {
    const token = yield select(getToken);
    const respData = yield call(createOffice, token, action.data);
    yield put(actions.createOfficeSuccess(respData));
    yield put(actions.setSelectedOffice(respData));
    yield put(
      actions.showSuccessNotification('New office saved successfully!')
    );
  } catch (error) {
    console.log(error);
    yield put(
      actions.showErrorNotification(
        'Failed to save new office. Please try again.'
      )
    );
    yield put(actions.saveOfficeFailed(error));
  }
}

function* deleteOfficeData(action: DeleteOfficeAction) {
  try {
    const token = yield select(getToken);
    yield call(deleteOffice, token, action.officeId);
    yield put(actions.deleteOfficeSuccess(action.officeId));
    yield put(actions.showSuccessNotification('Office deleted successfully!'));
  } catch (error) {
    console.log(error);
    yield put(
      actions.showErrorNotification(
        'Failed to delete office. Please try again.'
      )
    );
    yield put(actions.deleteOfficeFailed(action.officeId, error));
  }
}

export default function* officeSaga() {
  yield takeEvery(ActionTypes.OFFICES_PAGE_ENTER, loadOfficesInitData);
  yield takeEvery(ActionTypes.SAVE_OFFICE, saveOfficeData);
  yield takeEvery(ActionTypes.CREATE_OFFICE, createOfficeData);
  yield takeEvery(ActionTypes.DELETE_OFFICE, deleteOfficeData);
}
