import { ActionTypes } from '../actions/actionTypes';
import {
  Currency,
  Language,
  TimeZone,
  Country,
  HolidayCalendar,
} from '../../models/Picklist';
import { Action } from '../actions/interfaces/types';

export interface PicklistState {
  currencies: Currency[];
  languages: Language[];
  timezones: TimeZone[];
  countries: Country[];
  holidayCalendars: HolidayCalendar[];
}

const initialState = {
  currencies: [],
  languages: [],
  timezones: [],
  countries: [],
  holidayCalendars: [],
};

const reducer = (
  state: PicklistState = initialState,
  action: Action
): PicklistState => {
  switch (action.type) {
    case ActionTypes.FETCH_CURRENCIES_SUCCESS:
      return {
        ...state,
        currencies: action.currencies,
      };
    case ActionTypes.FETCH_LANGUAGES_SUCCESS:
      return {
        ...state,
        languages: action.languages,
      };
    case ActionTypes.FETCH_TIMEZONES_SUCCESS:
      return {
        ...state,
        timezones: action.timezones,
      };
    case ActionTypes.FETCH_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.countries,
      };
    case ActionTypes.FETCH_HOLIDAY_CALENDAR_SUCCESS:
      return {
        ...state,
        holidayCalendars: action.holidayCalendars,
      };
    default:
      return state;
  }
};

export default reducer;
