import React, {Component} from 'react';

class Employees extends Component {
  render() {
    return (
      <div>
        <p>Dashboard</p>
        <p>Employee Filters</p>
        <p>Employee Page</p>
      </div>
    );
  }
}

export default Employees;