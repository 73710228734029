import { all } from 'redux-saga/effects';
import authSaga from './auth';
import companySaga from './companySaga';
import officeSaga from './officesSaga';
import departmentSaga from './departmentSaga';
import teamSaga from './teamSaga';
import positionSaga from "./positionSaga";
import documentTypeSaga from "./documentTypeSaga";
import occupationTypeSaga from "./occupationTypeSaga";

export default function* appSagas() {
  yield all([
    authSaga(),
    companySaga(),
    officeSaga(),
    departmentSaga(),
    teamSaga(),
    positionSaga(),
    documentTypeSaga(),
    occupationTypeSaga(),
  ]);
}
