import axios from 'axios';

let subDomain = '';
let baseURL = '';
const isDevEnvironment = process.env.NODE_ENV === 'development';

if (isDevEnvironment) {
  subDomain =  window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;

  if (!subDomain) {
    throw new Error('Failed to parse client account. Account: ' + subDomain);
  }
  baseURL = 'http://api.magehrm.local/api/v1';
} else {
  baseURL = '/backyard';
}

const instance = axios.create({
  baseURL: baseURL,
});

if (isDevEnvironment) {
  instance.defaults.headers.common['X-SubDomain'] = 'pentagon'; //subDomain;
}
//axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getState().session.token;

// const axiosNoToken = axios.create({baseURL: apiURL});
// axiosNoToken.defaults.headers.common['X-Client-Name'] = subDomain;
// export const axiosNoToken;

export default instance;
