import { DocumentType } from '../../models/DocumentType';
import { ActionTypes } from '../actions/actionTypes';
import { Action } from '../actions/interfaces/types';

export interface DocumentTypeState {
    loading: boolean;
    list: DocumentType[];
    selectedDocumentType: DocumentType | null;
}

const initialStates: DocumentTypeState = {
    loading: false,
    list: [],
    selectedDocumentType: null,
};

const updateDocumentTypeByName = (
    state: DocumentTypeState,
    documentType: DocumentType
) => {
    const newDocumentTypeList = state.list.map((element: DocumentType) => {
        if (element.name === documentType.name) {
            return documentType;
        }

        return element;
    });

    return {
        ...state,
        loading: false,
        list: newDocumentTypeList,
    };
};

const reducer = (
    state: DocumentTypeState = initialStates,
    action: Action
): DocumentTypeState => {
    switch (action.type) {
        case ActionTypes.DOCUMENT_TYPE_PAGE_ENTER:
            return {
                ...state,
                loading: true,
            };
        case ActionTypes.DOCUMENT_TYPE_PAGE_ENTER_SUCCESS:
            return {
                ...state,
                loading: false,
                list: action.documentTypes,
            };
        case ActionTypes.SET_SELECTED_DOCUMENT_TYPE:
            const documentTypes = [...state.list];
            const selectedDocumentType = documentTypes.find((d) => d.id === action.id);
            return {
                ...state,
                selectedDocumentType: selectedDocumentType ?? null,
            };
        case ActionTypes.SAVE_DOCUMENT_TYPE:
            return {
                ...state,
                loading: true,
            };
        case ActionTypes.SAVE_DOCUMENT_TYPE_SUCCESS:
            return updateDocumentTypeByName(state, action.documentType);
        case ActionTypes.DELETE_DOCUMENT_TYPE:
            return {
                ...state,
                loading: true,
            };
        case ActionTypes.DELETE_DOCUMENT_TYPE_SUCCESS:
            return {
                ...state,
                list: state.list.filter(
                    (documentType: DocumentType) => documentType.id !== action.id
                ),
                loading: false,
            };
        case ActionTypes.DELETE_DOCUMENT_TYPE_FAILED:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export default reducer;
