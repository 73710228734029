import { call, put, select, takeEvery } from 'redux-saga/effects';
import { ActionTypes } from '../actions/actionTypes';
import { StoreState } from '../reducers';
import * as actions from '../actions';
import {
  getOccupationTypes,
  updateOccupationType,
  deleteOccupationType,
  createOccupationType,
} from '../../services/occupationTypes';
import {
  DeleteOccupationTypeAction,
  SaveOccupationTypeAction,
} from '../actions/interfaces/occupationType';

const getToken = (state: StoreState) => state.auth.token;

function* loadOccupationTypes(): any {
  try {
    const token = yield select(getToken);
    const occupationTypes = yield call(getOccupationTypes, token);

    yield put(actions.occupationTypePageEnterSuccess(occupationTypes));
  } catch (error) {
    console.log(error);
    yield put(actions.showErrorNotification(error.message));
  }
}

function* saveOccupationType(action: SaveOccupationTypeAction): any {
  try {
    const token = yield select(getToken);

    yield call(
      action.occupationType.id === -1 ? createOccupationType : updateOccupationType,
      token,
      action.occupationType
    );

    yield put(
      actions.showSuccessNotification(
        `${action.occupationType.name} has been successfully updated.`
      )
    );
    yield put(actions.saveOccupationTypeSuccess(action.occupationType));
    yield loadOccupationTypes();
  } catch (error) {
    console.log(error);
    yield put(actions.showErrorNotification(error.message));
  }
}

function* removeOccupationType(action: DeleteOccupationTypeAction): any {
  try {
    const token = yield select(getToken);
    yield call(deleteOccupationType, token, action.id);

    yield put(
      actions.showSuccessNotification(
        `OccupationType has been successfully removed.`
      )
    );
    yield put(actions.deleteOccupationTypeSuccess(action.id));
    yield loadOccupationTypes();
  } catch (error) {
    console.log(error);
    yield put(actions.showErrorNotification(`An error occurred while try to delete the occupationType`));
    yield put(actions.deleteOccupationTypeFailed());
  }
}

export default function* occupationTypeSaga() {
  yield takeEvery(ActionTypes.OCCUPATION_TYPE_PAGE_ENTER, loadOccupationTypes);
  yield takeEvery(ActionTypes.SAVE_OCCUPATION_TYPE, saveOccupationType);
  yield takeEvery(ActionTypes.DELETE_OCCUPATION_TYPE, removeOccupationType);
}
