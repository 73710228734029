import { DocumentType } from '../../models/DocumentType';
import { ActionTypes } from './actionTypes';
import {
    DocumentTypePageEnterAction,
    DocumentTypePageEnterSuccessAction,
    SetSelectedDocumentTypeAction,
    SaveDocumentTypeAction,
    SaveDocumentTypeSuccessAction,
    DeleteDocumentTypeAction,
    DeleteDocumentTypeSuccessAction,
    DeleteDocumentTypeFailedAction,
} from './interfaces/documentType';

export const documentTypePageEnter = (): DocumentTypePageEnterAction => {
    return {
        type: ActionTypes.DOCUMENT_TYPE_PAGE_ENTER,
    };
};

export const documentTypePageEnterSuccess = (
    documentTypes: DocumentType[]
): DocumentTypePageEnterSuccessAction => {
    return {
        type: ActionTypes.DOCUMENT_TYPE_PAGE_ENTER_SUCCESS,
        documentTypes: documentTypes,
    };
};

export const setSelectedDocumentType = (
    id: number | null
): SetSelectedDocumentTypeAction => {
    return {
        type: ActionTypes.SET_SELECTED_DOCUMENT_TYPE,
        id: id,
    };
};

export const saveDocumentType = (
    documentType: DocumentType
): SaveDocumentTypeAction => {
    return {
        type: ActionTypes.SAVE_DOCUMENT_TYPE,
        documentType: documentType,
    };
};

export const saveDocumentTypeSuccess = (
    documentType: DocumentType
): SaveDocumentTypeSuccessAction => {
    return {
        type: ActionTypes.SAVE_DOCUMENT_TYPE_SUCCESS,
        documentType: documentType,
    };
};

export const deleteDocumentType = (id: number): DeleteDocumentTypeAction => {
    return {
        type: ActionTypes.DELETE_DOCUMENT_TYPE,
        id: id,
    };
};

export const deleteDocumentTypeSuccess = (
    id: number
): DeleteDocumentTypeSuccessAction => {
    return {
        type: ActionTypes.DELETE_DOCUMENT_TYPE_SUCCESS,
        id: id,
    };
};

export const deleteDocumentTypeFailed = (): DeleteDocumentTypeFailedAction => {
    return {
        type: ActionTypes.DELETE_DOCUMENT_TYPE_FAILED,
    };
};
