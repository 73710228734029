import React from 'react';
import { Col, Row } from 'antd';
import classes from './Offices.module.css';

export interface ViewData {
  field: string;
  value: string;
}

export interface ViewOfficeProps {
  data: ViewData[];
}

const ViewOffice = ({ data }: ViewOfficeProps): JSX.Element => {
  return (
    <div>
      {data.map((rowData: any, index: number) => (
        <Row className={classes.FormRow} key={index}>
          <Col span={8} className={classes.ValueField}>
            {rowData.field}
          </Col>
          <Col span={16} className={classes.ValueRow}>
            {rowData.value}
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default ViewOffice;
