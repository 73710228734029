import { Store } from 'antd/lib/form/interface';
import { Office } from '../../../models/Office';
import { OfficeFormData, OfficeRequestData } from './Interfaces';
import { ViewData } from './ViewOffice';

export class OfficeFormMapper {
  mapToFormData(office: Office): OfficeFormData {
    return {
      id: office.id,
      name: office.name,
      currency: office.currency?.id,
      timezone: office.timezone.id,
      street: office.address.street,
      house_number: office.address.house_number,
      address_2: office.address.address_2 || '',
      postcode: office.address.postcode,
      city: office.address.city,
      state: office.address.state || '',
      country: office.address.country.id,
      public_holiday: office.holiday_calendar.id,
    };
  }

  mapToRequestData(formData: OfficeFormData | Store): OfficeRequestData {
    return {
      id: formData.id,
      name: formData.name,
      timezone: formData.timezone,
      currency: formData.currency,
      holiday_calendar: formData.public_holiday,
      address: {
        street: formData.street,
        house_number: formData.house_number,
        address_line_2: formData.address_2,
        postal_code: formData.postcode,
        city: formData.city,
        state: formData.state,
        country: formData.country,
      },
    };
  }

  mapToViewData(office: Office | null): ViewData[] {
    const address = office?.address;
    const currency = office?.currency;
    const country = office?.address?.country;
    const houseNumber = address?.house_number
      ? address?.house_number + ', '
      : '';
    const streetNameAndHouseNumber = houseNumber + (address?.street || '');
    const symbolWithParenthesis = currency?.symbol
      ? `(${currency?.symbol})`
      : '';
    const currencyWithSymbol = `${
      currency?.code || ''
    } ${symbolWithParenthesis}`;
    const postalCodeCity =
      (address?.postcode ? address?.postcode + ', ' : '') +
      (address?.city || '');
    const stateCountry =
      (address?.state ? address?.state + ' ,' : '') + (country?.name || '');

    return [
      { field: 'Office Name', value: office?.name || '' },
      { field: 'Currency', value: currencyWithSymbol },
      { field: 'Timezone', value: office?.timezone?.name || '' },
      {
        field: 'Street, House Number',
        value: streetNameAndHouseNumber,
      },
      {
        field: 'Other Address Information',
        value: address?.address_2 || '',
      },
      { field: 'Postal Code, City', value: postalCodeCity },
      { field: 'State, Country', value: stateCountry },
      { field: 'Public Holiday', value: office?.holiday_calendar?.name || '' },
    ];
  }
}
