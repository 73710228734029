import React, {Component} from 'react';
import {Layout} from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons';
import classes from './Toobar.module.css';

const {Header} = Layout;

class Toolbar extends Component {
  render() {
    return (
      <div className={classes.Toolbar}>
        <Header className={classes.Toolbar} style={{ padding: 0 }}>
          {React.createElement(this.props.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: classes.Trigger,
            onClick: this.props.toggleCollapse,
          })}
        </Header>
      </div>
    );
  }
}

export default Toolbar;