import React, { MouseEvent } from 'react';
import classes from './FormActionButton.module.css';
import { Button, Form } from 'antd';
import { ArrowRightOutlined, CloseOutlined } from '@ant-design/icons';

interface FormActionButtonProps {
  loading?: boolean;
  disabled?: boolean;
  onSubmit?: (event: MouseEvent) => void;
  onCancel?: (event: MouseEvent) => void;
}

const FormActionButton: React.FC<FormActionButtonProps> = ({
  loading,
  onSubmit,
  onCancel,
  disabled,
}: FormActionButtonProps) => {
  return (
    <div className={classes.FormActionButtons}>
      <Form.Item wrapperCol={{ span: 24 }}>
        <Button icon={<CloseOutlined />} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          type="primary"
          icon={<ArrowRightOutlined />}
          htmlType="submit"
          loading={loading}
          disabled={disabled}
          onClick={onSubmit}
        >
          Save Changes
        </Button>
      </Form.Item>
    </div>
  );
};

export default FormActionButton;
