import { Team } from '../../models/Team';
import { ActionTypes } from '../actions/actionTypes';
import { Action } from '../actions/interfaces/types';

export interface TeamState {
    loading: boolean;
    list: Team[];
    selectedTeam: Team | null;
}

const initialStates: TeamState = {
    loading: false,
    list: [],
    selectedTeam: null,
};

const updateTeamByName = (
    state: TeamState,
    team: Team
) => {
    const newTeamList = state.list.map((element: Team) => {
        if (element.name === team.name) {
            return team;
        }

        return element;
    });

    return {
        ...state,
        loading: false,
        list: newTeamList,
    };
};

const reducer = (
    state: TeamState = initialStates,
    action: Action
): TeamState => {
    switch (action.type) {
        case ActionTypes.TEAM_PAGE_ENTER:
            return {
                ...state,
                loading: true,
            };
        case ActionTypes.TEAM_PAGE_ENTER_SUCCESS:
            return {
                ...state,
                loading: false,
                list: action.teams,
            };
        case ActionTypes.SET_SELECTED_TEAM:
            const teams = [...state.list];
            const selectedTeam = teams.find((d) => d.id === action.id);
            return {
                ...state,
                selectedTeam: selectedTeam ?? null,
            };
        case ActionTypes.SAVE_TEAM:
            return {
                ...state,
                loading: true,
            };
        case ActionTypes.SAVE_TEAM_SUCCESS:
            return updateTeamByName(state, action.team);
        case ActionTypes.DELETE_TEAM:
            return {
                ...state,
                loading: true,
            };
        case ActionTypes.DELETE_TEAM_SUCCESS:
            return {
                ...state,
                list: state.list.filter(
                    (team: Team) => team.id !== action.id
                ),
                loading: false,
            };
        case ActionTypes.DELETE_TEAM_FAILED:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export default reducer;
