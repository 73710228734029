import { Department, DepartmentResponse } from '../models/Department';
import axios from '../utils/axios-backend';

export const getDepartments = (token: string) => {
  return axios
    .get('/setup/departments', {
      headers: { Authorization: 'Bearer ' + token },
    })
    .then((response) => mapResponseToModel(response.data.data));
};

export const updateDepartment = (token: string, department: Department) => {
  return axios
    .put(`/setup/departments/${department.id}`, mapModelToRequest(department), {
      headers: { Authorization: 'Bearer ' + token },
    })
    .then((response) => response.data.data);
};

export const createDepartment = (token: string, department: Department) => {
  return axios
    .post(`/setup/departments`, mapModelToRequest(department), {
      headers: { Authorization: 'Bearer ' + token },
    })
    .then((response) => response.data.data);
};

export const deleteDepartment = (token: string, id: number) => {
  return axios
    .delete(`/setup/departments/${id}`, {
      headers: { Authorization: 'Bearer ' + token },
    })
    .then((response) => response.data.data);
};

const mapResponseToModel = (
  departmentList: DepartmentResponse[]
): Department[] => {
  return departmentList.map(
    (resp: DepartmentResponse): Department => {
      return {
        key: resp.id,
        id: resp.id,
        name: resp.name,
        workingHours: resp.working_hours,
      };
    }
  );
};

const mapModelToRequest = (dept: Department) => {
  return {
    id: -1 === dept.id ? null : dept.id,
    name: dept.name,
    working_hours: dept.workingHours,
  };
};
