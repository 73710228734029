import { call, put, select, takeEvery } from 'redux-saga/effects';
import { ActionTypes } from '../actions/actionTypes';
import { StoreState } from '../reducers';
import * as actions from '../actions';
import {
  getTeams,
  updateTeam,
  deleteTeam,
  createTeam,
} from '../../services/teams';
import {
  DeleteTeamAction,
  SaveTeamAction,
} from '../actions/interfaces/team';

const getToken = (state: StoreState) => state.auth.token;

function* loadTeams(): any {
  try {
    const token = yield select(getToken);
    const teams = yield call(getTeams, token);

    yield put(actions.teamPageEnterSuccess(teams));
  } catch (error) {
    console.log(error);
    yield put(actions.showErrorNotification(error.message));
  }
}

function* saveTeam(action: SaveTeamAction): any {
  try {
    const token = yield select(getToken);

    yield call(
      action.team.id === -1 ? createTeam : updateTeam,
      token,
      action.team
    );

    yield put(
      actions.showSuccessNotification(
        `${action.team.name} has been successfully updated.`
      )
    );
    yield put(actions.saveTeamSuccess(action.team));
    yield loadTeams();
  } catch (error) {
    console.log(error);
    yield put(actions.showErrorNotification(error.message));
  }
}

function* removeTeam(action: DeleteTeamAction): any {
  try {
    const token = yield select(getToken);
    yield call(deleteTeam, token, action.id);

    yield put(
      actions.showSuccessNotification(
        `Team has been successfully removed.`
      )
    );
    yield put(actions.deleteTeamSuccess(action.id));
    yield loadTeams();
  } catch (error) {
    console.log(error);
    yield put(actions.showErrorNotification(`An error occurred while try to delete the team`));
    yield put(actions.deleteTeamFailed());
  }
}

export default function* teamSaga() {
  yield takeEvery(ActionTypes.TEAM_PAGE_ENTER, loadTeams);
  yield takeEvery(ActionTypes.SAVE_TEAM, saveTeam);
  yield takeEvery(ActionTypes.DELETE_TEAM, removeTeam);
}
