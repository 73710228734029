import { Department } from '../../models/Department';
import { ActionTypes } from '../actions/actionTypes';
import { Action } from '../actions/interfaces/types';

export interface DepartmentState {
  loading: boolean;
  list: Department[];
  selectedDepartment: Department | null;
}

const initialStates: DepartmentState = {
  loading: false,
  list: [],
  selectedDepartment: null,
};

const updateDepartmentById = (
  state: DepartmentState,
  department: Department
) => {
  const newDepartments = state.list.map((element: Department) => {
    if (element.name === department.name) {
      return department;
    }

    return element;
  });

  return {
    ...state,
    loading: false,
    list: newDepartments,
  };
};

const reducer = (
  state: DepartmentState = initialStates,
  action: Action
): DepartmentState => {
  switch (action.type) {
    case ActionTypes.DEPARTMENT_PAGE_ENTER:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.DEPARTMENT_PAGE_ENTER_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.departments,
      };
    case ActionTypes.SET_SELECTED_DEPARTMENT:
      const departments = [...state.list];
      const selectedDept = departments.find((d) => d.id === action.id);
      return {
        ...state,
        selectedDepartment: selectedDept ?? null,
      };
    case ActionTypes.SAVE_DEPARTMENT:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.SAVE_DEPARTMENT_SUCCESS:
      return updateDepartmentById(state, action.department);
    case ActionTypes.DELETE_DEPARTMENT:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.DELETE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        list: state.list.filter(
          (department: Department) => department.id !== action.id
        ),
        loading: false,
      };
    case ActionTypes.DELETE_DEPARTMENT_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
