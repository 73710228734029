import { EmployeePosition } from '../../models/EmployeePosition';
import { StoreState } from '../reducers';

export const positionList = (state: StoreState): EmployeePosition[] =>
  state.positions.list.map<EmployeePosition>((position) => {
    return { key: position.id, ...position };
  });

export const isLoading = (state: StoreState): boolean =>
  state.positions.loading;

export const getSelectedPosition = (state: StoreState): EmployeePosition | null =>
  state.positions.selectedPosition;
