import { takeEvery, put, call } from 'redux-saga/effects';
import { ActionTypes } from '../actions/actionTypes';
import * as actions from '../actions';
import {
  login as loginService,
  logout as logoutService,
} from '../../services/auth';

function* login(action) {
  try {
    yield put(actions.authStart());
    const authData = yield call(loginService, action.email, action.password);
    const jwtData = authData.data.jwt;

    localStorage.setItem('token', jwtData.token);
    const expiry = new Date(jwtData.expiry * 1000);
    localStorage.setItem('expiry', expiry.toString());

    yield put(actions.authSuccess(jwtData.token, jwtData.expiry));
  } catch (err) {
    console.log(err);
    yield put(actions.authFailed(err));
  }
}

function* logout(action) {
  const token = localStorage.getItem('token');
  if (token && !action.tokenExpired) {
    const resp = yield call(logoutService, token);
    console.log(resp);
  }
  localStorage.removeItem('expiry');
  yield put(actions.authLogoutSuccess());
}

function* authCheck() {
  const token = yield localStorage.getItem('token');
  const expiryDate = yield localStorage.getItem('expiry');
  const expiry = expiryDate ? new Date(expiryDate).getTime() / 1000 : 0;
  const now = new Date().getTime() / 1000;
  if (!token || now >= expiry) {
    yield* logout({ tokenExpired: true });
    return;
  }

  yield put(actions.checkIsAuthSuccess(token, expiry));
}

export default function* authSaga() {
  yield takeEvery(ActionTypes.AUTH_LOGIN, login);
  yield takeEvery(ActionTypes.AUTH_CHECK, authCheck);
  yield takeEvery(ActionTypes.AUTH_LOGOUT, logout);
}
