import { Office } from '../../models/Office';
import { ActionTypes } from './actionTypes';
import {
  AddNewOfficeAction,
  CreateOfficeAction,
  CreateOfficeSuccessAction,
  DeleteOfficeAction,
  OfficePageEnterAction,
  OfficePageEnterSuccessAction,
  SaveOfficeAction,
  SaveOfficeFailedAction,
  SaveOfficeSuccessAction,
  DeleteOfficeSuccessAction,
  DeleteOfficeFailedAction,
  SetSelectedOfficeAction,
} from './interfaces/office';
import { OfficeRequestData } from '../../containers/Configuration/Offices/Interfaces';

export const officePageEnter = (): OfficePageEnterAction => {
  return {
    type: ActionTypes.OFFICES_PAGE_ENTER,
  };
};

export const officePageEnterSuccess = (
  offices: Office[]
): OfficePageEnterSuccessAction => {
  return {
    type: ActionTypes.OFFICES_PAGE_ENTER_SUCCESS,
    offices: offices,
  };
};

export const setSelectedOffice = (office: Office): SetSelectedOfficeAction => {
  return {
    type: ActionTypes.SET_SELECTED_OFFICE,
    office,
  };
};

//@Todo Find out the type for this
export const saveOffice = (formData: OfficeRequestData): SaveOfficeAction => {
  return {
    type: ActionTypes.SAVE_OFFICE,
    data: formData,
  };
};

//@Todo Find out the type for this
export const createOffice = (
  formData: OfficeRequestData
): CreateOfficeAction => {
  return {
    type: ActionTypes.CREATE_OFFICE,
    data: formData,
  };
};

//@Todo Find out the type for this
export const saveOfficeSuccess = (
  officeData: Office
): SaveOfficeSuccessAction => {
  return {
    type: ActionTypes.SAVE_OFFICE_SUCCESS,
    office: officeData,
  };
};

//@Todo Find out the type for this
export const createOfficeSuccess = (
  officeData: Office
): CreateOfficeSuccessAction => {
  return {
    type: ActionTypes.CREATE_OFFICE_SUCCESS,
    office: officeData,
  };
};

export const saveOfficeFailed = (error: Error): SaveOfficeFailedAction => {
  return {
    type: ActionTypes.SAVE_OFFICE_FAILED,
    error,
  };
};

export const addNewOffice = (newOffice: Office): AddNewOfficeAction => {
  return {
    type: ActionTypes.ADD_NEW_OFFICE,
    office: newOffice,
  };
};

export const deleteOffice = (officeId: number): DeleteOfficeAction => {
  return {
    type: ActionTypes.DELETE_OFFICE,
    officeId,
  };
};

export const deleteOfficeSuccess = (
  officeId: number
): DeleteOfficeSuccessAction => {
  return {
    type: ActionTypes.DELETE_OFFICE_SUCCESS,
    officeId,
  };
};

export const deleteOfficeFailed = (
  officeId: number,
  error: Error
): DeleteOfficeFailedAction => {
  return {
    type: ActionTypes.DELETE_OFFICE_FAILED,
    officeId,
    error,
  };
};
