import { call, put, select } from '@redux-saga/core/effects';
import {
  getCountries,
  getCurrencies,
  getLanguages,
  getTimezones,
  getHolidayCalendars,
} from '../../services/picklists';
import * as actions from '../actions';

const getToken = (state) => state.auth.token;

export function* _loadCurrencies() {
  try {
    const selectCurrencies = (state) => state.picklist.currencies;
    let currencies = yield select(selectCurrencies);
    if (0 !== currencies.length) {
      return;
    }

    const token = yield select(getToken);
    currencies = yield call(getCurrencies, token);
    yield put(actions.fetchCurrenciesSuccess(currencies));
  } catch (error) {
    console.log(error);
  }
}

export function* _loadTimezones() {
  try {
    const selectTimezones = (state) => state.picklist.timezones;
    let timezones = yield select(selectTimezones);
    if (0 !== timezones.length) {
      return;
    }

    const token = yield select(getToken);
    timezones = yield call(getTimezones, token);
    yield put(actions.fetchTimezonesSuccess(timezones));
  } catch (error) {
    console.log(error);
  }
}

export function* loadLanguages() {
  try {
    const selectLanguages = (state) => state.picklist.languages;
    let languages = yield select(selectLanguages);
    if (0 !== languages.length) {
      return;
    }

    const token = yield select(getToken);
    languages = yield call(getLanguages, token);
    yield put(actions.fetchLanguagesSuccess(languages));
  } catch (error) {
    console.log(error);
  }
}

export function* _loadCountries() {
  try {
    const selectCountries = (state) => state.picklist.countries;
    let countries = yield select(selectCountries);
    if (0 !== countries.length) {
      return;
    }

    const token = yield select(getToken);
    countries = yield call(getCountries, token);
    yield put(actions.fetchCountriesSuccess(countries));
  } catch (error) {
    console.log(error);
  }
}

export function* _loadHolidayCalendars() {
  try {
    const selectHolidayCalendars = (state) => state.picklist.holidayCalendars;
    let holidayCalendars = yield select(selectHolidayCalendars);
    if (0 !== holidayCalendars.length) {
      return;
    }

    const token = yield select(getToken);
    holidayCalendars = yield call(getHolidayCalendars, token);
    yield put(actions.fetchHolidayCalendarSuccess(holidayCalendars));
  } catch (error) {
    console.log(error);
  }
}
