import React, {Component} from 'react';
import {Menu} from "antd";
import {
  ScheduleOutlined,
  SettingOutlined,
  MoneyCollectOutlined, ThunderboltOutlined, FundOutlined, TeamOutlined,
} from '@ant-design/icons';
import {NavLink} from "react-router-dom";
const {SubMenu} = Menu;

class NavigationItems extends Component {
  render() {
    return (
      <div>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
          <Menu.Item key="employees">
            <TeamOutlined />
            <NavLink to="/employees">Employee</NavLink>
          </Menu.Item>
          <Menu.Item key="absence">
            <ScheduleOutlined />
            <NavLink to="/absence">Absence</NavLink>
          </Menu.Item>
          <Menu.Item key="performance">
            <ThunderboltOutlined />
            <NavLink to="/performance">Performance</NavLink>
          </Menu.Item>
          <Menu.Item key="payroll">
            <MoneyCollectOutlined />
            <span>Payroll</span>
          </Menu.Item>
          <Menu.Item key="reports">
            <FundOutlined />
            <span>Reports</span>
          </Menu.Item>
          <SubMenu
            key="settings"
            title={
              <span>
                  <SettingOutlined />
                  <span>Settings</span>
                </span>
            }
          >
            <Menu.Item key="c_company"><NavLink to="/configuration/company">Company</NavLink></Menu.Item>
            <Menu.Item key="c_offices"><NavLink to="/configuration/offices">Offices</NavLink></Menu.Item>
            <Menu.Item key="c_departments"><NavLink to="/configuration/departments">Departments</NavLink></Menu.Item>
            <Menu.Item key="c_team"><NavLink to="/configuration/teams">Teams</NavLink></Menu.Item>
            <Menu.Item key="c_roles"><NavLink to="/configuration/roles">Roles</NavLink></Menu.Item>
            <Menu.Item key="c_positions"><NavLink to="/configuration/positions">Positions</NavLink></Menu.Item>
            <Menu.Item key="c_document_types"><NavLink to="/configuration/document-types">Documents</NavLink></Menu.Item>
            <Menu.Item key="c_occupation_types"><NavLink to="/configuration/occupation-types">Occupation Types</NavLink></Menu.Item>
            <Menu.Item key="c_absence">Absence</Menu.Item>
            <Menu.Item key="c_public">Public Holidays</Menu.Item>
            <Menu.Item key="c_approvals">Approvals</Menu.Item>
            <Menu.Item key="c_salary">Salary</Menu.Item>
            <Menu.Item key="c_payroll">Payroll</Menu.Item>
            <Menu.Item key="c_authentication">Authentication</Menu.Item>
          </SubMenu>
        </Menu>
      </div>
    );
  }
}

export default NavigationItems;