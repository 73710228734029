import React, { FunctionComponent } from 'react';
import { message } from 'antd';
import Aux from '../Aux/Aux';

interface Notification {
  show: boolean;
  type: string;
  content: string;
  duration?: number;
}

const Notification: FunctionComponent<Notification> = ({
  show,
  type,
  content,
  duration,
  children,
}) => {
  if (!duration) {
    duration = type === 'error' ? 6 : 3;
  }

  if (show) {
    switch (type) {
      case 'info':
        message.info(content, duration);
        break;
      case 'warn':
      case 'warning':
        message.warn(content, duration);
        break;
      case 'error':
        message.error(content, duration);
        break;
      case 'success':
        message.success(content, duration);
        break;
    }
  }

  return <Aux>{children}</Aux>;
};

export default Notification;
