import React from 'react';
import classes from './Offices.module.css';
import { Button, List /* Tag */ } from 'antd';

interface OfficeSideBarItem {
  index: number;
  officeName: string;
  officeId: number;
  isHeadOffice: boolean;
  officeClicked: (officeId: number) => void;
}

const OfficeSideBarItem = ({
  index,
  officeName,
  officeId,
  isHeadOffice,
  officeClicked,
}: OfficeSideBarItem): JSX.Element => {
  return (
    <List.Item className={index % 2 === 0 ? classes.ListRows : ''}>
      <List.Item.Meta
        title={
          <>
            <Button type="link" onClick={() => officeClicked(officeId)}>
              {officeName}
              {isHeadOffice ? /* <Tag color="blue">hq</Tag> */ ' ' : ''}
            </Button>
          </>
        }
      />
    </List.Item>
  );
};

export default OfficeSideBarItem;
