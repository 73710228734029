import React from 'react';
import {Select} from "antd";
const {Option} = Select;

const MSelect = ({picklist, style, ...rest}) => {
  return (
    <Select style={style} {...rest}>
      {picklist ?
        picklist.map((item) => (
          <Option key={item.id} value={item.id}>{item.name}</Option>
        )) : null
      }
    </Select>
  );
};

export default MSelect;