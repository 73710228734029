import axios from '../utils/axios-backend';

export const login = (email, password) => {
  return axios.post('/auth/login', {email, password})
    .then(response => response.data);
};

export const logout = (token) => {
  return axios.post(
    '/auth/logout',
    {},
    {
      headers: { Authorization: "Bearer " + token }
    })
    .then(response => response.data);
};