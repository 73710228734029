import React from 'react';
import './App.css';
import Routers from './router';
import { useSelector } from 'react-redux';
import Notification from './hoc/Notification/Notification';
import { StoreState } from './store/reducers';

function App(): JSX.Element {
  const notification = useSelector((state: StoreState) => state.notification);

  return (
    <div className="App">
      <Notification
        show={notification.show}
        type={notification.type}
        content={notification.message}
      >
        <Routers />
      </Notification>
    </div>
  );
}

export default App;
