import axios from '../utils/axios-backend';

export const getCurrencies = (token) => {
  return axios.get('/pl/currencies',
    {
      headers: { Authorization: "Bearer " + token }
    })
    .then(response => response.data.data);
};

export const getTimezones = (token) => {
  return axios.get('/pl/timezones',
    {
      headers: { Authorization: "Bearer " + token }
    })
    .then(response => response.data.data);
};

export const getLanguages = (token) => {
  return axios.get('/pl/languages',
    {
      headers: { Authorization: "Bearer " + token }
    })
    .then(response => response.data.data);
};

export const getIndustryCategories = (token) => {
  return axios.get('/pl/industry_categories',
    {
      headers: { Authorization: "Bearer " + token }
    })
    .then(response => response.data.data);
};

export const getCountries = (token) => {
  return axios.get('/pl/countries',
    {
      headers: { Authorization: "Bearer " + token }
    })
    .then(response => response.data.data);
};

export const getHolidayCalendars = (token) => {
  return axios.get('/pl/holiday_calendars',
    {
      headers: { Authorization: "Bearer " + token }
    })
    .then(response => response.data.data);
};