import { ActionTypes } from '../actions/actionTypes';
import { Office } from '../../models/Office';
import { Action } from '../actions/interfaces/types';

export interface OfficeState {
  loading: boolean;
  list: Office[];
  selectedOffice: Office | null;
}

const initialStates = {
  loading: false,
  list: [],
  selectedOffice: null,
};

function updateOffice(state: OfficeState, office: Office) {
  const newOffices = state.list.map((element) => {
    if (element.id === office.id) {
      return {
        ...element,
        ...office,
      };
    }

    return element;
  });

  return {
    ...state,
    list: newOffices,
    loading: false,
  };
}

function updateOfficeByName(state: OfficeState, office: Office) {
  const newOffices = state.list.map((element: Office) => {
    if (element.name === office.name) {
      return office;
    }

    return element;
  });

  return {
    ...state,
    list: newOffices,
    loading: false,
  };
}

const reducer = (
  state: OfficeState = initialStates,
  action: Action
): OfficeState => {
  switch (action.type) {
    case ActionTypes.OFFICES_PAGE_ENTER:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.OFFICES_PAGE_ENTER_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.offices,
      };
    case ActionTypes.SET_SELECTED_OFFICE:
      return {
        ...state,
        selectedOffice: action.office,
      };
    case ActionTypes.CREATE_OFFICE:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.CREATE_OFFICE_SUCCESS:
      return updateOfficeByName(state, action.office);
    case ActionTypes.SAVE_OFFICE:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.SAVE_OFFICE_SUCCESS:
      return updateOffice(state, action.office);
    case ActionTypes.SAVE_OFFICE_FAILED:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.ADD_NEW_OFFICE:
      let offices = [...state.list];
      offices.push(action.office);
      return {
        ...state,
        list: offices,
      };
    case ActionTypes.DELETE_OFFICE:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.DELETE_OFFICE_SUCCESS:
      const newList = state.list.filter(
        (office: Office) => office.id !== action.officeId
      );
      const firstOffice = newList[0] || null;

      return {
        ...state,
        list: state.list.filter(
          (office: Office) => office.id !== action.officeId
        ),
        loading: false,
        selectedOffice: firstOffice,
      };
    case ActionTypes.DELETE_OFFICE_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
