import { ActionTypes } from '../actions/actionTypes';
import { Action } from '../actions/interfaces/types';

export interface AuthState {
  token: string | null;
  expiry: number | null;
  error: string | null;
  loading: boolean;
  isAuthenticated: boolean;
}

const initialState: AuthState = {
  token: null,
  expiry: null,
  error: null,
  loading: false,
  isAuthenticated: false,
};

const reducer = (
  state: AuthState = initialState,
  action: Action
): AuthState => {
  switch (action.type) {
    case ActionTypes.AUTH_START:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.AUTH_SUCCESS:
      return {
        ...state,
        token: action.token,
        expiry: action.expiry,
        loading: false,
        isAuthenticated: true,
      };
    case ActionTypes.AUTH_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
        isAuthenticated: false,
      };
    case ActionTypes.AUTH_CHECK_SUCCESS:
      return {
        ...state,
        token: action.token,
        expiry: action.expiry,
        isAuthenticated: true,
      };
    case ActionTypes.AUTH_LOGOUT_SUCCESS:
      return {
        ...state,
        token: null,
        expiry: null,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

export default reducer;
