import React from 'react';
import { Office } from '../../../models/Office';
import { List } from 'antd';
import OfficeSideBarItem from './OfficeSideBarItem';

interface OfficeSideBarListProps {
  offices: Office[];
  officeClicked: (officeId: number) => void;
}

const OfficeSideBarList = ({
  offices,
  officeClicked,
}: OfficeSideBarListProps): JSX.Element => {
  return (
    <List
      size="small"
      bordered
      itemLayout="horizontal"
      dataSource={offices}
      renderItem={(office: Office, index: number) => (
        <OfficeSideBarItem
          index={index}
          officeName={office.name}
          officeId={office.id}
          isHeadOffice={office.is_head_quarter}
          officeClicked={officeClicked}
        />
      )}
    />
  );
};

export default OfficeSideBarList;
