import { ActionTypes } from '../actions/actionTypes';
import * as actions from '../actions';
import { takeEvery, put, call, select, all } from 'redux-saga/effects';
import { getCompanyProfile, saveCompanyProfile } from '../../services/company';
import {
  getCurrencies,
  getIndustryCategories,
  getLanguages,
  getTimezones,
} from '../../services/picklists';

const getToken = (state) => state.auth.token;

function* fetchProfile(action) {
  try {
    const token = yield select(getToken);
    yield put(actions.getProfileStart());
    const profile = yield call(getCompanyProfile, token);
    yield put(actions.getProfileSuccess(profile));
  } catch (err) {
    console.log(err);
    yield put(actions.getProfileFailed(err));
  }
}

function* initCompanyProfile(action) {
  try {
    const token = yield select(getToken);
    const [currencies, languages, timezones, industryCategories] = yield all([
      call(getCurrencies, token),
      call(getLanguages, token),
      call(getTimezones, token),
      call(getIndustryCategories, token),
    ]);
    const picklist = { currencies, languages, timezones, industryCategories };
    yield put(actions.initCompanyProfileSuccess(picklist));
  } catch (error) {
    console.log(error);
    yield put(actions.initCompanyProfileFailed(error));
  }
}

function* updateCompanyProfile(action) {
  try {
    const token = yield select(getToken);
    yield put(actions.companyProfileUpdateStart());
    const response = yield call(saveCompanyProfile, token, action.payload);

    yield put(
      actions.showSuccessNotification('Company Profile saved successfully!')
    );
    yield put(actions.companyProfileUpdateSuccess(response.data));
  } catch (error) {
    console.log(error);
    yield put(
      actions.showErrorNotification(
        'Failed to Save company profile. Please try again.'
      )
    );
    yield put(actions.companyProfileUpdateFailed(error));
  }
}

export default function* companySaga() {
  yield takeEvery(ActionTypes.FETCH_COMPANY_PROFILE, fetchProfile);
  yield takeEvery(ActionTypes.COMPANY_PROFILE_ENTER, initCompanyProfile);
  yield takeEvery(ActionTypes.COMPANY_PROFILE_UPDATE, updateCompanyProfile);
}
