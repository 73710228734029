import React, {Component} from 'react';
import classes from './Layout.module.css';
import {Layout} from 'antd';
import Sidebar from "../../components/Navigation/Sidebar/Sidebar";
import Toolbar from "../../components/Navigation/Toolbar/Toolbar";

const {Content, Footer} = Layout;

class AppLayout extends Component {

  state = {
    collapsed: false
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    return (
      <Layout style={{minHeight: '100vh'}}>
        <Sidebar isCollapsed={this.state.collapsed} />
        <Layout className={classes.SiteLayout}>
          <Toolbar isCollapsed={this.state.collapsed} toggleCollapse={this.toggle}/>
          <Content className={[classes.SiteLayout, classes.Content]}>
            {this.props.children}
          </Content>
          <Footer style={{textAlign: 'center'}}>MageHRM ©2020</Footer>
        </Layout>
      </Layout>
    );
  }
}

export default AppLayout;