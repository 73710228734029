import React, {Component} from 'react';
import {Form, Input, Button, Checkbox} from 'antd';
import {UserOutlined, LockOutlined} from '@ant-design/icons';
import {Row, Col} from 'antd';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';

import classes from './Login.module.css';
import {Redirect} from "react-router-dom";

class Login extends Component {

  onFinish = values => {
    this.props.onLoginIn(values.username, values.password);
  };

  render() {
    let redirectToHome = null;
    if (null !== this.props.token) {
      redirectToHome = <Redirect to={this.props.location.state.from} />;
    }

    return (
      <Row>
        {redirectToHome}
        <Col span={18}>col-8</Col>
        <Col span={6}>
          <div className={classes.LoginFormContainer}>
            <div className={classes.MiddleWrapper}>
              <div className={classes.LoginFormWrapper}>
                <Form
                  name="normal_login"
                  className={classes.LoginForm}
                  initialValues={{remember: true}}
                  onFinish={this.onFinish}
                >
                  <Form.Item
                    name="username"
                    rules={[{required: true, message: 'Please input your Username!'}]}
                  >
                    <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder="Username"/>
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[{required: true, message: 'Please input your Password!'}]}
                  >
                    <Input
                      prefix={<LockOutlined className="site-form-item-icon"/>}
                      type="password"
                      placeholder="Password"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                      <Checkbox>Remember me</Checkbox>
                    </Form.Item>

                    <a className={classes.LoginFormForgot} href="/password/forgot">
                      Forgot password
                    </a>
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" htmlType="submit" loading={this.props.loading} className={classes.LoginFormButton}>
                      Log in
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    token: state.auth.token,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLoginIn: (email, password) => dispatch(actions.authLogin(email, password))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);