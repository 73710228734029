import { Team } from '../../models/Team';
import { StoreState } from '../reducers';

export const teamList = (state: StoreState): Team[] =>
  state.teams.list.map<Team>((team) => {
    return { key: team.id, ...team };
  });

export const isLoading = (state: StoreState): boolean =>
  state.teams.loading;

export const getSelectedTeam = (state: StoreState): Team | null =>
  state.teams.selectedTeam;
