import React, { createRef, useEffect, useState } from 'react';
import classes from './Offices.module.css';
import { Alert, Button, Col, Input, Row, Spin, Tag, Modal } from 'antd';
import {
  PlusOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import ButtonIcon from '../../../components/UI/ButtonIcon';
import EditOffice from './EditOffice';
import ViewOffice, { ViewData } from './ViewOffice';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectedOffice,
  officeDefault,
  officeList,
  officeProcessState,
} from '../../../store/selectors/officeSelector';
import {
  countryList,
  currencyList,
  timezoneList,
  holidayCalendarList,
} from '../../../store/selectors/picklistSelector';
import { Office } from '../../../models/Office';
import * as actions from '../../../store/actions';
import OfficeSideBarList from './OfficeSideBarList';
import { OfficeFormData } from './Interfaces';
import { OfficeFormMapper } from './OfficeFormTransformer';
import { Store } from 'antd/lib/form/interface';

const { confirm } = Modal;

const Offices = () => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const dispatch = useDispatch();

  //@Todo Find out the type for this
  const newOfficeInput = createRef<any>();

  const currencies = useSelector(currencyList);
  const timezones = useSelector(timezoneList);
  const countries = useSelector(countryList);
  const publicHolidays = useSelector(holidayCalendarList);
  const offices = useSelector(officeList);
  const isLoading = useSelector(officeProcessState);
  const defaultOffice = useSelector(officeDefault);
  const selectedOffice = useSelector(getSelectedOffice);

  const isOfficeEmpty = offices.length === 0;

  useEffect(() => {
    dispatch(actions.officePageEnter());
  }, [dispatch]);

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const getViewData = (office: Office | null): ViewData[] => {
    return new OfficeFormMapper().mapToViewData(office);
  };

  const officeClicked = (officeId: number): void => {
    setIsEditing(false);
    const selectedOffice = offices.find(
      (office: Office) => office.id === officeId
    );

    if (!selectedOffice) {
      return;
    } else {
      dispatch(actions.setSelectedOffice(selectedOffice));
    }
  };

  /**
   * Save existing office
   * @param formData
   * @param id
   */
  const saveOfficeHandler = (formData: OfficeFormData | Store, id: number) => {
    formData.id = id;
    const data = new OfficeFormMapper().mapToRequestData(formData);
    dispatch(actions.saveOffice(data));
  };

  /**
   * Handler for creating new office
   * @param formData
   */
  const createOfficeHandler = (formData: OfficeFormData | Store) => {
    const data = new OfficeFormMapper().mapToRequestData(formData);
    dispatch(actions.createOffice(data));
  };

  const officeNameAlreadyExist = (officeName: string): boolean => {
    return (
      offices.filter((office: Office) => office.name === officeName).length > 0
    );
  };

  const addNewOfficeName = (): void => {
    const newOfficeName = newOfficeInput.current.state.value;
    if (officeNameAlreadyExist(newOfficeName)) {
      return;
    }

    let newOffice = defaultOffice;
    newOffice.name = newOfficeName;
    dispatch(actions.addNewOffice(newOffice));
  };

  const removeOffice = (): void => {
    if (!selectedOffice?.id) {
      return;
    }

    confirm({
      title: `Are you sure delete this office - ${selectedOffice?.name}? `,
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        dispatch(actions.deleteOffice(selectedOffice?.id));
      },
      onCancel() {
        console.log(`Cancelled deletion of office - ${selectedOffice?.name}`);
      },
    });
  };

  const loadingControl = (
    <div style={{ textAlign: 'center', margin: '30px 0' }}>
      <Spin tip="Loading..." />
    </div>
  );

  return (
    <div>
      <div className={classes.Offices}>
        <div className={classes.TitleContainer}>
          <h2>Offices</h2>
        </div>

        <Alert
          style={{ marginTop: '21px' }}
          message="This account was pre-filled with demo employees. To clear all demo data, please complete the setup wizard."
          type="warning"
        />

        <Row className={classes.Content}>
          {isLoading ? (
            loadingControl
          ) : isOfficeEmpty ? (
            `You haven't setup any office yet!`
          ) : (
            <>
              <Col span={6}>
                <div className={classes.SubTitle}>
                  <h3>Offices</h3>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'stretch',
                    padding: '10px 0px',
                  }}
                >
                  <Input
                    placeholder="New Office"
                    style={{ width: '90%' }}
                    ref={newOfficeInput}
                  />
                  <Button
                    style={{ flexGrow: 1 }}
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => addNewOfficeName()}
                  />
                </div>
                <div>
                  <OfficeSideBarList
                    offices={offices}
                    officeClicked={officeClicked}
                  />
                </div>
              </Col>
              <Col span={16} offset={1}>
                <div className={classes.SubTitle}>
                  <h3>
                    {selectedOffice?.name}{' '}
                    {!selectedOffice?.id ? <Tag color="red">unsaved</Tag> : ''}
                  </h3>
                  <span className={classes.EditAction} onClick={toggleEdit}>
                    {isEditing ? ' [Done]' : ' [Edit]'}
                  </span>
                  <ButtonIcon onClick={removeOffice}>
                    <DeleteOutlined />
                  </ButtonIcon>
                </div>
                {isEditing ? (
                  <EditOffice
                    office={selectedOffice}
                    currencies={currencies}
                    timezones={timezones}
                    countries={countries}
                    publicHolidays={publicHolidays}
                    loading={isLoading}
                    onSaveOffice={(formData: Store) =>
                      selectedOffice?.id
                        ? saveOfficeHandler(formData, selectedOffice.id)
                        : createOfficeHandler(formData)
                    }
                    onCancel={toggleEdit}
                  />
                ) : (
                  <ViewOffice data={getViewData(selectedOffice)} />
                )}
              </Col>
            </>
          )}
        </Row>
      </div>
    </div>
  );
};

export default Offices;
