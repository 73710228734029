import React from 'react';
import Home from '../containers/Home/Home';
import Login from '../containers/Auth/Login/Login';
import AppLayout from '../hoc/AppLayout/AppLayout';
import Employees from '../containers/Employees/Employees';
import Absence from '../containers/Configuration/Absence/Absence';
import Company from '../containers/Configuration/Company/Company';
import AbsenceConfig from '../containers/Absence/Absence';
import Logout from '../containers/Auth/Logout/Logout';
import { Route, Switch } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute/ProtectedRoute';
import Offices from '../containers/Configuration/Offices/Offices';
import Departments from '../containers/Configuration/Departments/Departments';
import Teams from '../containers/Configuration/Teams/Teams';
import Positions from "../containers/Configuration/Positions/Positions";
import DocumentTypes from "../containers/Configuration/DocumentTypes/DocumentTypes";
import OccupationTypes from "../containers/Configuration/OccupationTypes/OccupationTypes";

const Routers = () => {
  return (
    <div>
      <Switch>
        <Route path="/login" component={Login} />

        <Route>
          <AppLayout>
            <Switch>
              <ProtectedRoute path="/employees" component={Employees} />
              <ProtectedRoute path="/absence" component={Absence} />
              {/*<ProtectedRoute path="/configuration" component={Company}/>*/}
              <ProtectedRoute
                path="/configuration/company"
                component={Company}
              />
              <ProtectedRoute
                path="/configuration/absence"
                component={AbsenceConfig}
              />
              <ProtectedRoute
                path="/configuration/offices"
                component={Offices}
              />
              <ProtectedRoute
                path="/configuration/departments"
                component={Departments}
              />
              <ProtectedRoute
                  path="/configuration/teams"
                  component={Teams}
              />
              <ProtectedRoute
                  path="/configuration/positions"
                  component={Positions}
              />
              <ProtectedRoute
                  path="/configuration/document-types"
                  component={DocumentTypes}
              />
              <ProtectedRoute
                  path="/configuration/occupation-types"
                  component={OccupationTypes}
              />
              <ProtectedRoute path="/logout" component={Logout} />
              <ProtectedRoute path="/" exact component={Home} />
            </Switch>
          </AppLayout>
        </Route>
      </Switch>
    </div>
  );
};

export default Routers;
