import { DocumentType } from '../../models/DocumentType';
import { StoreState } from '../reducers';

export const documentTypeList = (state: StoreState): DocumentType[] =>
  state.documentTypes.list.map<DocumentType>((documentType) => {
    return { key: documentType.id, ...documentType };
  });

export const isLoading = (state: StoreState): boolean =>
  state.documentTypes.loading;

export const getSelectedDocumentType = (state: StoreState): DocumentType | null =>
  state.documentTypes.selectedDocumentType;
