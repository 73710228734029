import { ActionTypes } from './actionTypes';
import {
  AuthSuccessAction,
  AuthStartAction,
  AuthFailedAction,
  AuthLoginAction,
  AuthLogoutAction,
  AuthLogoutSuccessAction,
  AuthCheckAction,
  AuthCheckSuccessAction,
} from './interfaces/auth';

export const authStart = (): AuthStartAction => {
  return {
    type: ActionTypes.AUTH_START,
  };
};

export const authSuccess = (
  token: string,
  expiry: number
): AuthSuccessAction => {
  return {
    type: ActionTypes.AUTH_SUCCESS,
    token: token,
    expiry: expiry,
  };
};

//@Todo Find out the type for this
export const authFailed = (error: any): AuthFailedAction => {
  return {
    type: ActionTypes.AUTH_FAILED,
    error: error,
  };
};

export const authLogin = (email: string, password: string): AuthLoginAction => {
  return {
    type: ActionTypes.AUTH_LOGIN,
    email: email,
    password: password,
  };
};

export const authLogout = (): AuthLogoutAction => {
  return {
    type: ActionTypes.AUTH_LOGOUT,
  };
};

export const authLogoutSuccess = (): AuthLogoutSuccessAction => {
  return {
    type: ActionTypes.AUTH_LOGOUT_SUCCESS,
  };
};

export const checkIsAuth = (): AuthCheckAction => {
  return {
    type: ActionTypes.AUTH_CHECK,
  };
};

export const checkIsAuthSuccess = (
  token: string,
  expiry: number
): AuthCheckSuccessAction => {
  return {
    type: ActionTypes.AUTH_CHECK_SUCCESS,
    token: token,
    expiry: expiry,
  };
};
