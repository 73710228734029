import React, {Component} from 'react';
import {Redirect, Route} from "react-router-dom";
import * as actions from '../../store/actions';
import {connect} from "react-redux";

class ProtectedRoute extends Component {

  componentDidMount() {
    this.props.checkIsAuthenticated()
  }

  render() {
    const {component:Component, isAuthenticated, ...rest} = this.props;

    if (!isAuthenticated) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: {from: this.props.location}
          }}
        />
      );
    }

    return (
      <Route {...rest} component={Component} />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  }
};

const matchDispatchToProps = (dispatch) => {
  return {
    checkIsAuthenticated: () => dispatch(actions.checkIsAuth())
  }
};

export default connect(mapStateToProps, matchDispatchToProps)(ProtectedRoute);