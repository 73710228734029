import { combineReducers } from 'redux';
import authReducer, { AuthState } from './authReducer';
import companyReducer, { CompanyState } from './companyReducer';
import officeReducer, { OfficeState } from './officesReducer';
import notificationReducer, { NotificationState } from './notificationReducer';
import picklistReducer, { PicklistState } from './picklistReducer';
import departmentReducer, { DepartmentState } from './departmentReducer';
import teamReducer, { TeamState } from './teamReducer';
import positionReducer, { PositionState } from './positionReducer';
import documentTypeReducer, { DocumentTypeState } from './documentTypeReducer';
import OccupationTypeReducer, { OccupationTypeState } from './occupationTypeReducer';

export interface StoreState {
  auth: AuthState;
  company: CompanyState;
  notification: NotificationState;
  offices: OfficeState;
  picklist: PicklistState;
  departments: DepartmentState;
  teams: TeamState;
  positions: PositionState;
  documentTypes: DocumentTypeState;
  occupationTypes: OccupationTypeState;
}

const appReducer = combineReducers<StoreState>({
  auth: authReducer,
  company: companyReducer,
  notification: notificationReducer,
  offices: officeReducer,
  picklist: picklistReducer,
  departments: departmentReducer,
  teams: teamReducer,
  positions: positionReducer,
  documentTypes: documentTypeReducer,
  occupationTypes: OccupationTypeReducer,
});

export default appReducer;
