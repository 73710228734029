import React, {Component} from 'react';
import {Layout} from 'antd';
import NavigationItems from "./NavigationItems/NavigationItems";
import classes from './Sidebar.module.css';

const {Sider} = Layout;

class Sidebar extends Component {
  render() {
    return (
      <Sider className={classes.SideBar} trigger={null} collapsible collapsed={this.props.isCollapsed}>
        <div className={classes.Logo}/>
        <NavigationItems/>
      </Sider>
    );
  }
}

export default Sidebar;