import { EmployeePosition } from '../../models/EmployeePosition';
import { ActionTypes } from './actionTypes';
import {
    PositionPageEnterAction,
    PositionPageEnterSuccessAction,
    SetSelectedPositionAction,
    SavePositionAction,
    SavePositionSuccessAction,
    DeletePositionAction,
    DeletePositionSuccessAction,
    DeletePositionFailedAction,
} from './interfaces/position';

export const positionPageEnter = (): PositionPageEnterAction => {
    return {
        type: ActionTypes.POSITION_PAGE_ENTER,
    };
};

export const positionPageEnterSuccess = (
    positions: EmployeePosition[]
): PositionPageEnterSuccessAction => {
    return {
        type: ActionTypes.POSITION_PAGE_ENTER_SUCCESS,
        positions: positions,
    };
};

export const setSelectedPosition = (
    id: number | null
): SetSelectedPositionAction => {
    return {
        type: ActionTypes.SET_SELECTED_POSITION,
        id: id,
    };
};

export const savePosition = (
    position: EmployeePosition
): SavePositionAction => {
    return {
        type: ActionTypes.SAVE_POSITION,
        position: position,
    };
};

export const savePositionSuccess = (
    position: EmployeePosition
): SavePositionSuccessAction => {
    return {
        type: ActionTypes.SAVE_POSITION_SUCCESS,
        position: position,
    };
};

export const deletePosition = (id: number): DeletePositionAction => {
    return {
        type: ActionTypes.DELETE_POSITION,
        id: id,
    };
};

export const deletePositionSuccess = (
    id: number
): DeletePositionSuccessAction => {
    return {
        type: ActionTypes.DELETE_POSITION_SUCCESS,
        id: id,
    };
};

export const deletePositionFailed = (): DeletePositionFailedAction => {
    return {
        type: ActionTypes.DELETE_POSITION_FAILED,
    };
};
