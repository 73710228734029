import { ActionTypes } from '../actions/actionTypes';
import { Action } from '../actions/interfaces/types';
import {EmployeePosition} from "../../models/EmployeePosition";

export interface PositionState {
    loading: boolean;
    list: EmployeePosition[];
    selectedPosition: EmployeePosition | null;
}

const initialStates: PositionState = {
    loading: false,
    list: [],
    selectedPosition: null,
};

const updatePositionByName = (
    state: PositionState,
    position: EmployeePosition
) => {
    const newPositionList = state.list.map((element: EmployeePosition) => {
        if (element.name === position.name) {
            return position;
        }

        return element;
    });

    return {
        ...state,
        loading: false,
        list: newPositionList,
    };
};

const reducer = (
    state: PositionState = initialStates,
    action: Action
): PositionState => {
    switch (action.type) {
        case ActionTypes.POSITION_PAGE_ENTER:
            return {
                ...state,
                loading: true,
            };
        case ActionTypes.POSITION_PAGE_ENTER_SUCCESS:
            return {
                ...state,
                loading: false,
                list: action.positions,
            };
        case ActionTypes.SET_SELECTED_POSITION:
            const positions = [...state.list];
            const selectedPosition = positions.find((d) => d.id === action.id);
            return {
                ...state,
                selectedPosition: selectedPosition ?? null,
            };
        case ActionTypes.SAVE_POSITION:
            return {
                ...state,
                loading: true,
            };
        case ActionTypes.SAVE_POSITION_SUCCESS:
            return updatePositionByName(state, action.position);
        case ActionTypes.DELETE_POSITION:
            return {
                ...state,
                loading: true,
            };
        case ActionTypes.DELETE_POSITION_SUCCESS:
            return {
                ...state,
                list: state.list.filter(
                    (position: EmployeePosition) => position.id !== action.id
                ),
                loading: false,
            };
        case ActionTypes.DELETE_POSITION_FAILED:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export default reducer;
