import { ActionTypes } from '../actions/actionTypes';
import {
  Currency,
  Language,
  TimeZone,
  IndustryCategory,
} from '../../models/Picklist';
import { Action } from '../actions/interfaces/types';

//@Todo Fix all 'any' in this file
export interface CompanyState {
  profile: any;
  picklist: {
    currencies: Currency[] | null;
    languages: Language[] | null;
    timezones: TimeZone[] | null;
    industryCategories: IndustryCategory[] | null;
  };
  loading: boolean;
  error: any;
}

const initialState = {
  profile: null,
  picklist: {
    currencies: null,
    languages: null,
    timezones: null,
    industryCategories: null,
  },
  loading: false,
  error: null,
};

const reducer = (
  state: CompanyState = initialState,
  action: Action
): CompanyState => {
  switch (action.type) {
    case ActionTypes.FETCH_COMPANY_PROFILE_START:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.FETCH_COMPANY_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.company,
        error: null,
      };
    case ActionTypes.FETCH_COMPANY_PROFILE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ActionTypes.COMPANY_PROFILE_ENTER:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.COMPANY_PROFILE_ENTER_SUCCESS:
      return {
        ...state,
        loading: false,
        picklist: action.picklist,
        error: null,
      };
    case ActionTypes.COMPANY_PROFILE_ENTER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ActionTypes.COMPANY_PROFILE_UPDATE_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionTypes.COMPANY_PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.updatedProfile,
      };
    case ActionTypes.COMPANY_PROFILE_UPDATE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default reducer;
