import React from 'react';
import { Form, Input } from 'antd';
import classes from './Offices.module.css';
import MSelect from '../../../components/UI/MSelect';
import FormActionButton from '../../../components/UI/FormActionButtons/FormActionButton';
import { FormProps } from 'antd/lib/form';
import { Office } from '../../../models/Office';
import {
  Country,
  Currency,
  PublicHoliday,
  TimeZone,
} from '../../../models/Picklist';
import { OfficeFormData } from './Interfaces';
import { OfficeFormMapper } from './OfficeFormTransformer';
import { Store } from 'antd/lib/form/interface';

interface EditOfficeProps {
  office: Office | null;
  currencies: Currency[];
  timezones: TimeZone[];
  countries: Country[];
  publicHolidays: PublicHoliday[];
  onSaveOffice: (formData: Store) => void;
  onCancel: () => void;
  loading: boolean;
}

const EditOffice: React.FC<EditOfficeProps> = ({
  office,
  currencies,
  timezones,
  countries,
  publicHolidays,
  onSaveOffice,
  onCancel,
  loading,
}): JSX.Element => {
  const layout: FormProps = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
    layout: 'horizontal',
  };

  let initialValues: OfficeFormData | {} = {};
  if (office) {
    initialValues = new OfficeFormMapper().mapToFormData(office);
  }

  return (
    <div>
      <Form
        {...layout}
        labelAlign="left"
        colon={false}
        initialValues={initialValues}
        onFinish={(values: Store) => onSaveOffice(values)}
      >
        <Form.Item name="name" label="Office Name" className={classes.FormRow}>
          <Input placeholder="Company Name" />
        </Form.Item>
        <Form.Item name="currency" label="Currency" className={classes.FormRow}>
          <MSelect picklist={currencies} style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item name="timezone" label="Timezone" className={classes.FormRow}>
          <MSelect picklist={timezones} style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item label="Street, House Number" className={classes.FormRow}>
          <Form.Item
            name="street"
            style={{
              display: 'inline-block',
              width: 'calc(70% - 8px)',
              margin: '0',
            }}
          >
            <Input placeholder="Street" />
          </Form.Item>
          <Form.Item
            name="house_number"
            style={{
              display: 'inline-block',
              width: 'calc(30% - 8px)',
              margin: '0 0 0 8px',
            }}
          >
            <Input placeholder="House Number" />
          </Form.Item>
        </Form.Item>

        <Form.Item
          name="address_2"
          label="Additional Address Information"
          className={classes.FormRow}
        >
          <Input placeholder="Additional Address Information" />
        </Form.Item>

        <Form.Item label="Postal Code, City" className={classes.FormRow}>
          <Form.Item
            name="postcode"
            style={{
              display: 'inline-block',
              width: 'calc(40% - 8px)',
              margin: '0',
            }}
          >
            <Input placeholder="Postal Code" />
          </Form.Item>
          <Form.Item
            name="city"
            style={{
              display: 'inline-block',
              width: 'calc(60% - 8px)',
              margin: '0 0 0 8px',
            }}
          >
            <Input placeholder="City" />
          </Form.Item>
        </Form.Item>
        <Form.Item label="State, Country" className={classes.FormRow}>
          <Form.Item
            name="state"
            style={{
              display: 'inline-block',
              width: 'calc(40% - 8px)',
              margin: '0',
            }}
          >
            <Input placeholder="State" />
          </Form.Item>
          <Form.Item
            name="country"
            style={{
              display: 'inline-block',
              width: 'calc(60% - 8px)',
              margin: '0 0 0 8px',
            }}
          >
            <MSelect picklist={countries} style={{ width: '100%' }} />
          </Form.Item>
        </Form.Item>
        <Form.Item
          name="public_holiday"
          label="Public Holiday"
          className={classes.FormRow}
        >
          <MSelect picklist={publicHolidays} style={{ width: '100%' }} />
        </Form.Item>

        <FormActionButton loading={loading} onCancel={onCancel} />
      </Form>
    </div>
  );
};

export default EditOffice;
