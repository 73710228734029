export enum ActionTypes {
  AUTH_LOGIN = 'AUTH_LOGIN',
  AUTH_LOGOUT = 'AUTH_LOGOUT',
  AUTH_START = 'AUTH_START',
  AUTH_SUCCESS = 'AUTH_SUCCESS',
  AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS',
  AUTH_FAILED = 'AUTH_FAILED',
  AUTH_CHECK = 'AUTH_CHECK',
  AUTH_CHECK_SUCCESS = 'AUTH_CHECK_SUCCESS',
  FETCH_COMPANY_PROFILE_START = 'FETCH_COMPANY_PROFILE_START',
  FETCH_COMPANY_PROFILE = 'FETCH_COMPANY_PROFILE',
  FETCH_COMPANY_PROFILE_SUCCESS = 'FETCH_COMPANY_PROFILE_SUCCESS',
  FETCH_COMPANY_PROFILE_FAILED = 'COMPANY_PROFILE_ENTER_FAILED',
  COMPANY_PROFILE_ENTER = 'COMPANY_PROFILE_EDIT_ENTER',
  COMPANY_PROFILE_ENTER_SUCCESS = 'COMPANY_PROFILE_ENTER_SUCCESS',
  COMPANY_PROFILE_ENTER_FAILED = 'COMPANY_PROFILE_ENTER_FAILED',
  COMPANY_PROFILE_UPDATE = 'COMPANY_PROFILE_UPDATE',
  COMPANY_PROFILE_UPDATE_START = 'COMPANY_PROFILE_UPDATE_START',
  COMPANY_PROFILE_UPDATE_SUCCESS = 'COMPANY_PROFILE_UPDATE_SUCCESS',
  COMPANY_PROFILE_UPDATE_FAILED = 'COMPANY_PROFILE_UPDATE_FAILED',
  SUCCESS_NOTIFICATION_SHOWED = 'SUCCESS_NOTIFICATION_SHOWED',
  ERROR_NOTIFICATION_SHOWED = 'ERROR_NOTIFICATION_SHOWED',
  NOTIFICATION_HIDDEN = 'NOTIFICATION_HIDDEN',
  OFFICES_PAGE_ENTER = 'OFFICES_PAGE_ENTER',
  OFFICES_PAGE_ENTER_SUCCESS = 'OFFICES_PAGE_ENTER_SUCCESS',
  SET_SELECTED_OFFICE = 'SET_SELECTED_OFFICE',
  ADD_NEW_OFFICE = 'ADD_NEW_OFFICE',
  CREATE_OFFICE = 'CREATE_OFFICE',
  CREATE_OFFICE_SUCCESS = 'CREATE_OFFICE_SUCCESS',
  SAVE_OFFICE = 'SAVE_OFFICE',
  DELETE_OFFICE = 'DELETE_OFFICE',
  DELETE_OFFICE_SUCCESS = 'DELETE_OFFICE_SUCCESS',
  DELETE_OFFICE_FAILED = 'DELETE_OFFICE_FAILED',
  SAVE_OFFICE_SUCCESS = 'SAVE_OFFICE_SUCCESS',
  SAVE_OFFICE_FAILED = 'SAVE_OFFICE_FAILED',
  FETCH_CURRENCIES_SUCCESS = 'FETCH_CURRENCIES_SUCCESS',
  FETCH_TIMEZONES_SUCCESS = 'FETCH_TIMEZONES_SUCCESS',
  FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS',
  FETCH_LANGUAGES_SUCCESS = 'FETCH_LANGUAGES_SUCCESS',
  FETCH_HOLIDAY_CALENDAR_SUCCESS = 'FETCH_HOLIDAY_CALENDAR_SUCCESS',

  DEPARTMENT_PAGE_ENTER = 'DEPARTMENT_PAGE_ENTER',
  DEPARTMENT_PAGE_ENTER_SUCCESS = 'DEPARTMENT_PAGE_ENTER_SUCCESS',
  SET_SELECTED_DEPARTMENT = 'SET_SELECTED_DEPARTMENT',
  SAVE_DEPARTMENT = 'SAVE_DEPARTMENT',
  SAVE_DEPARTMENT_SUCCESS = 'SAVE_DEPARTMENT_SUCCESS',
  DELETE_DEPARTMENT = 'DELETE_DEPARTMENT',
  DELETE_DEPARTMENT_SUCCESS = 'DELETE_DEPARTMENT_SUCCESS',
  DELETE_DEPARTMENT_FAILED = 'DELETE_DEPARTMENT_FAILED',

  TEAM_PAGE_ENTER = 'TEAM_PAGE_ENTER',
  TEAM_PAGE_ENTER_SUCCESS = 'TEAM_PAGE_ENTER_SUCCESS',
  SET_SELECTED_TEAM = 'SET_SELECTED_TEAM',
  SAVE_TEAM = 'SAVE_TEAM',
  SAVE_TEAM_SUCCESS = 'SAVE_TEAM_SUCCESS',
  DELETE_TEAM = 'DELETE_TEAM',
  DELETE_TEAM_SUCCESS = 'DELETE_TEAM_SUCCESS',
  DELETE_TEAM_FAILED = 'DELETE_TEAM_FAILED',

  POSITION_PAGE_ENTER = 'POSITION_PAGE_ENTER',
  POSITION_PAGE_ENTER_SUCCESS = 'POSITION_PAGE_ENTER_SUCCESS',
  SET_SELECTED_POSITION = 'SET_SELECTED_POSITION',
  SAVE_POSITION = 'SAVE_POSITION',
  SAVE_POSITION_SUCCESS = 'SAVE_POSITION_SUCCESS',
  DELETE_POSITION = 'DELETE_POSITION',
  DELETE_POSITION_SUCCESS = 'DELETE_POSITION_SUCCESS',
  DELETE_POSITION_FAILED = 'DELETE_POSITION_FAILED',

  DOCUMENT_TYPE_PAGE_ENTER = 'DOCUMENT_TYPE_PAGE_ENTER',
  DOCUMENT_TYPE_PAGE_ENTER_SUCCESS = 'DOCUMENT_TYPE_PAGE_ENTER_SUCCESS',
  SET_SELECTED_DOCUMENT_TYPE = 'SET_SELECTED_DOCUMENT_TYPE',
  SAVE_DOCUMENT_TYPE = 'SAVE_DOCUMENT_TYPE',
  SAVE_DOCUMENT_TYPE_SUCCESS = 'SAVE_DOCUMENT_TYPE_SUCCESS',
  DELETE_DOCUMENT_TYPE = 'DELETE_DOCUMENT_TYPE',
  DELETE_DOCUMENT_TYPE_SUCCESS = 'DELETE_DOCUMENT_TYPE_SUCCESS',
  DELETE_DOCUMENT_TYPE_FAILED = 'DELETE_DOCUMENT_TYPE_FAILED',

  OCCUPATION_TYPE_PAGE_ENTER = 'OCCUPATION_TYPE_PAGE_ENTER',
  OCCUPATION_TYPE_PAGE_ENTER_SUCCESS = 'OCCUPATION_TYPE_PAGE_ENTER_SUCCESS',
  SET_SELECTED_OCCUPATION_TYPE = 'SET_SELECTED_OCCUPATION_TYPE',
  SAVE_OCCUPATION_TYPE = 'SAVE_OCCUPATION_TYPE',
  SAVE_OCCUPATION_TYPE_SUCCESS = 'SAVE_OCCUPATION_TYPE_SUCCESS',
  DELETE_OCCUPATION_TYPE = 'DELETE_OCCUPATION_TYPE',
  DELETE_OCCUPATION_TYPE_SUCCESS = 'DELETE_OCCUPATION_TYPE_SUCCESS',
  DELETE_OCCUPATION_TYPE_FAILED = 'DELETE_OCCUPATION_TYPE_FAILED',
}
