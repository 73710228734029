import {
  HideNotificationAction,
  ShowErrorNotificationAction,
  ShowSuccessNotificationAction
} from "./interfaces/notification";
import {ActionTypes} from "./actionTypes";

export const showSuccessNotification = (message: string): ShowSuccessNotificationAction => {
  return {
    type: ActionTypes.SUCCESS_NOTIFICATION_SHOWED,
    message: message,
  };
};

export const showErrorNotification = (message: string): ShowErrorNotificationAction => {
  return {
    type: ActionTypes.ERROR_NOTIFICATION_SHOWED,
    message: message,
  };
};

export const hideNotification = (): HideNotificationAction => {
  return {
    type: ActionTypes.NOTIFICATION_HIDDEN,
  };
};
