import { call, put, select, takeEvery } from 'redux-saga/effects';
import { ActionTypes } from '../actions/actionTypes';
import { StoreState } from '../reducers';
import * as actions from '../actions';
import {
  getDocumentTypes,
  updateDocumentType,
  deleteDocumentType,
  createDocumentType,
} from '../../services/documentTypes';
import {
  DeleteDocumentTypeAction,
  SaveDocumentTypeAction,
} from '../actions/interfaces/documentType';

const getToken = (state: StoreState) => state.auth.token;

function* loadDocumentTypes(): any {
  try {
    const token = yield select(getToken);
    const documentTypes = yield call(getDocumentTypes, token);

    yield put(actions.documentTypePageEnterSuccess(documentTypes));
  } catch (error) {
    console.log(error);
    yield put(actions.showErrorNotification(error.message));
  }
}

function* saveDocumentType(action: SaveDocumentTypeAction): any {
  try {
    const token = yield select(getToken);

    yield call(
      action.documentType.id === -1 ? createDocumentType : updateDocumentType,
      token,
      action.documentType
    );

    yield put(
      actions.showSuccessNotification(
        `${action.documentType.name} has been successfully updated.`
      )
    );
    yield put(actions.saveDocumentTypeSuccess(action.documentType));
    yield loadDocumentTypes();
  } catch (error) {
    console.log(error);
    yield put(actions.showErrorNotification(error.message));
  }
}

function* removeDocumentType(action: DeleteDocumentTypeAction): any {
  try {
    const token = yield select(getToken);
    yield call(deleteDocumentType, token, action.id);

    yield put(
      actions.showSuccessNotification(
        `DocumentType has been successfully removed.`
      )
    );
    yield put(actions.deleteDocumentTypeSuccess(action.id));
    yield loadDocumentTypes();
  } catch (error) {
    console.log(error);
    yield put(actions.showErrorNotification(`An error occurred while try to delete the documentType`));
    yield put(actions.deleteDocumentTypeFailed());
  }
}

export default function* documentTypeSaga() {
  yield takeEvery(ActionTypes.DOCUMENT_TYPE_PAGE_ENTER, loadDocumentTypes);
  yield takeEvery(ActionTypes.SAVE_DOCUMENT_TYPE, saveDocumentType);
  yield takeEvery(ActionTypes.DELETE_DOCUMENT_TYPE, removeDocumentType);
}
