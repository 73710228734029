import axios from '../utils/axios-backend';

export const getCompanyProfile = (token) => {
  return axios.get('/companies',
    {
      headers: { Authorization: "Bearer " + token }
    })
    .then(response => response.data);
};

export const saveCompanyProfile = (token, payload) => {
  return axios.post('/setup/companies', payload,
    {
      headers: { Authorization: "Bearer " + token }
    })
    .then(response => response.data);
};