import { call, put, select, takeEvery } from 'redux-saga/effects';
import { ActionTypes } from '../actions/actionTypes';
import { StoreState } from '../reducers';
import * as actions from '../actions';
import {
  getDepartments,
  updateDepartment,
  deleteDepartment,
  createDepartment,
} from '../../services/departments';
import {
  DeleteDepartmentAction,
  SaveDepartmentAction,
} from '../actions/interfaces/department';

const getToken = (state: StoreState) => state.auth.token;

function* loadDepartments(): any {
  try {
    const token = yield select(getToken);
    const departments = yield call(getDepartments, token);

    yield put(actions.departmentPageEnterSuccess(departments));
  } catch (error) {
    console.log(error);
    yield put(actions.showErrorNotification(error.message));
  }
}

function* saveDepartment(action: SaveDepartmentAction): any {
  try {
    const token = yield select(getToken);

    yield call(
      action.department.id === -1 ? createDepartment : updateDepartment,
      token,
      action.department
    );

    yield put(
      actions.showSuccessNotification(
        `${action.department.name} has been successfully updated.`
      )
    );
    yield put(actions.saveDepartmentSuccess(action.department));
    yield loadDepartments();
  } catch (error) {
    console.log(error);
    yield put(actions.showErrorNotification(error.message));
  }
}

function* removeDepartment(action: DeleteDepartmentAction): any {
  try {
    const token = yield select(getToken);
    yield call(deleteDepartment, token, action.id);

    yield put(
      actions.showSuccessNotification(
        `Department has been successfully removed.`
      )
    );
    yield put(actions.deleteDepartmentSuccess(action.id));
    yield loadDepartments();
  } catch (error) {
    console.log(error);
    yield put(actions.showErrorNotification(`An error occurred while try to delete the department`));
    yield put(actions.deleteDepartmentFailed());
  }
}

export default function* departmentSaga() {
  yield takeEvery(ActionTypes.DEPARTMENT_PAGE_ENTER, loadDepartments);
  yield takeEvery(ActionTypes.SAVE_DEPARTMENT, saveDepartment);
  yield takeEvery(ActionTypes.DELETE_DEPARTMENT, removeDepartment);
}
