import axios from '../utils/axios-backend';
import {EmployeePosition, PositionResponse} from '../models/EmployeePosition';

export const getPositions = (token: string) => {
  return axios
    .get('/setup/positions', {
      headers: { Authorization: 'Bearer ' + token },
    })
    .then((response) => mapResponseToModel(response.data.data));
};

export const updatePosition = (token: string, position: EmployeePosition) => {
  return axios
    .put(`/setup/positions/${position.id}`, mapModelToRequest(position), {
      headers: { Authorization: 'Bearer ' + token },
    })
    .then((response) => response.data.data);
};

export const createPosition = (token: string, position: EmployeePosition) => {
  return axios
    .post(`/setup/positions`, mapModelToRequest(position), {
      headers: { Authorization: 'Bearer ' + token },
    })
    .then((response) => response.data.data);
};

export const deletePosition = (token: string, id: number) => {
  return axios
    .delete(`/setup/positions/${id}`, {
      headers: { Authorization: 'Bearer ' + token },
    })
    .then((response) => response.data.data);
};

const mapResponseToModel = (
  positionList: PositionResponse[]
): EmployeePosition[] => {
  return positionList.map(
    (resp: PositionResponse): EmployeePosition => {
      return {
        key: resp.id,
        id: resp.id,
        name: resp.name,
      };
    }
  );
};

const mapModelToRequest = (position: EmployeePosition) => {
  return {
    id: -1 === position.id ? null : position.id,
    name: position.name,
  };
};
